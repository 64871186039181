.stepGetOffer {
	width: fit-content;
	//margin: 0px auto 40px auto;
	margin: 0 auto 0 auto;
	display: flex;
	//padding-top: 120px;
	
	.item {
		background: white;
		position: relative;
		color: #868f98;
		line-height: 26px;
		font-size: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 45px;
		height: 45px;
		border-radius: 10px;
		text-align: center;
		margin-right: 65px;
		&:last-child {
			margin-right: 0;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 20px;
			left: 55px;
			width: 45px;
			height: 1.5px;
			background: white;
		}
		&:last-child::after {
			display: none;
		}
	}
	.active {
		background: #3982cb;
		color: white;
	}
}
@media (max-width:660px) {
	.item {
		margin-right: 50px !important;
		&:last-child {
			margin-right: 0 !important;
		}
		&::after {
			left: 50px !important;
			width: 40px !important;
		}
	}

}
@media (max-width: 577px) {
	.stepGetOffer {
		display: none;
		.item {
			display: none;
			padding-top: 200px;
		}
	}
}
