.button {
	display: block;
	margin: auto;
	padding: 12.5px 38px;
	min-width: 320px;
	background: #F36326;
	border-radius: 19px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 150%;
	letter-spacing: 0.01em;
	color: #FFFFFF;
	margin-top: 30px;

	transition: all 300ms ease-in-out;
	//background-color: #f36326;
	//padding: 12px 45px;
	//width: fit-content;
	//margin: auto;
	//margin-top: 40px;
	//border-radius: 19px;
	//font-weight: 500;
	//font-size: 1.6rem;
	//color: white;
	&:hover {
		// margin-top: 30px;
		// padding: 25px 60px;
		transform: scale(1.1);
	}
 
}
.classDiv{
	text-align: center;
	margin-top: 30px;
}
@media (max-width: 576px) {
	.button {
		width: 100%;
    font-size: 22px;
    padding-top: 11px;
    padding-bottom: 11px;
	}
  .classDiv{
    margin-left:15px;
    margin-right: 15px;
  }
}
