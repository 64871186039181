.getOffer3 {
	color: white;

	.header {
		text-align: center;
		h1 {
			margin-top: 55px;
			margin-bottom: 10px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #FFFFFF;
			text-align: center;
		}
	}
  .people {
	  //display: flex;
	  //justify-content: space-between;
	  //max-width: 350px;
	  //align-items: center;
	  //margin: auto;
	  //font-family: 'Gilroy', sans-serif;
	  //font-style: normal;
	  //font-weight: 500;
	  //font-size: 28px;
	  //line-height: 120%;
	  //letter-spacing: 0.005em;
	  //color: #FFFFFF;
	  //margin-bottom: 24px;

	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  max-width: 350px;
	  margin: auto;
	  padding: 40px 0 40px;
	}
}
@media (max-width: 1199.98px) and (min-width: 576px) {
	.getOffer3 {
		.header {
			h1 {
				margin-left: 30px!important;
				margin-right: 30px;
			}
		}
	}
}

@media (max-width: 761px) {
	.getOffer3 {
		.header {
			text-align: left !important;
			h1 {
				//margin-bottom: 20px;
				margin-bottom: 0;
				margin-left: 15px;
				margin-right: 30px;
				text-align: left !important;
			}
		}
	}
}
@media (max-width: 576px) {
	.getOffer3 {
		.header {
			text-align: left !important;
			h1 {
				margin-top: 0!important;
				font-size: 30px!important;
				line-height: 32px!important;
			}
		}
	}
	.getOffer3 {
		.people {
			padding: 30px 0 40px;
		}
	}
}
@media (max-width: 377px) {
	.people {
		margin: 0 30px !important;
		width: auto !important;
		padding-top: 30px!important;
	}
}

