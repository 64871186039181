.boxForm {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 35px;
	input {
		background: transparent;
	}
	.request {
		position: relative;
		padding: 13px 45px;
		line-height: 22px;
		background: #f36326;
		color: white;
		font-size: 1.2rem;
		font-weight: 600;
		border-radius: 10px;
		transition: all 300ms ease;
		max-width: 250px;
		img {
			position: absolute;
			left: 10px;
			top: 20px;
		}
		&:hover {
			transform: scale(1.07);
		}
	}
}
@media (max-width: 1150px) {
	.boxForm {
		flex-direction: column;
		align-items: start;
		> div {
			width: 100% !important;
			margin-top: 20px;
		}
		.request {
			background: #f36326;
			width: 100%;
			color: white;
			font-weight: bold;
			border-radius: 10px;
			max-width: 100%;
		}
	}
	.customButton{
		text-align: center;
	}
}
@media (max-width:576px) {
	.request {
		padding: 8px 0 !important;
		font-size: 0.9rem !important;
	}
}
