.popularTours {
	@apply h-[600px] grid grid-rows-2 grid-cols-3 gap-4 m-auto my-10;
	.itemCard {
	}
}

@media (max-width:1200px) {
  .popularTours{
    @apply block h-auto;  
  }
}