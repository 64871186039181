.content {
	max-width: 1600px;
	margin: 120px auto 0 auto;
	h1 {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		line-height: 77px;
		color: #1D293F;

		text-align: center;

		margin-bottom: 125px;
		padding-top: 120px;
	}
	.table {

	}
	.link {
		color: #0d6efd;
	}
}
body .liTextPrivacyPolicy {
	padding: 0;
}
@media (max-width: 1660px) {
	.content {
		padding: 0 15px;
	}
}

@media (max-width: 1199px) {
	.content {
		padding: 0 30px;
		margin-top: 74px;
	}
}

@media (max-width: 992px) and (min-width: 576px) {
	.content {
		h1 {
			font-size: 50px;
			line-height: 60px;
		}
	}
}

@media (max-width: 576px) {
	.content {
		h1 {
			text-align: left;
			padding-top: 36px;
			font-size: 25px;
			line-height: 32px;
			margin-bottom: 36px;
		}
		.table {

		}
	}
}
