.arrow{
  border: 1px solid #546179;
  border-radius: 100%;
  padding: 8px;
  transition: all 200ms ease;
  svg{
    color:#546179;
    font-size: 1.8rem;
    font-weight: lighter;
  }
  &:hover{
    background: #3982CB;
    border:1px solid transparent;
    svg{
      color: white;
    }
  }
}