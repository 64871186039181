.content {
	margin-top: 160px;
	.header {
		text-align: center;
		h1 {
			font-size: 4rem;
			font-weight: 700;
			color: #1d293f;
		}
		p {
			font-size: 1.8rem;
			font-weight: 500;
			color: #546179;
		}
	}
	.card {
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		padding: 30px 40px;
		margin-top: 50px;
		.infoTour {
			display: flex;
			justify-content: left;
		}
		.twoItem {
			width: 20%;
		}
		.threeItem {
			width: 20%;
		}
		h3 {
			width: 25%;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 24px;
			margin-bottom: 20px;
		}
		.picture {
			width: 30%;
			margin-right: 20px;

			img {
				border-radius: 8px;
				width: 100%;
				object-fit: cover;
				height: 150px;
			}
		}
		.infoItem {
			line-height: 29px;
			h5 {
				color: #9a9ab0;
				font-size: 1.25rem;
				font-weight: 600;
				margin-bottom: 10px;
			}
			p {
				font-size: 1.6rem;
				font-weight: 600;
			}
		}
		.firstItem {
			width: 15%;
			min-width: min-content;
		}
		.changeItems {
			align-items: center;
			display: flex;
			// padding: 14px 45px;
			width: 244px;
			height: 67px;
			justify-content: center;
			background-color: #3982cb;
			color: white;
			font-size: 1.6rem;
			font-weight: 600;
			border-radius: 12px;
			transition: all 300ms ease;
			&:hover {
				transform: scale(1.08);
			}
			img {
				margin-left: 17px;
				width: 28px;
			}
		}
		.dopInfo {
			margin-top: 30px;
			ul {
				li {
					// display: flex;
					text-align: left;
					margin-top: 10px;
					font-size: 1.2rem;
					font-weight: bold;
					p {
						display: inline;
						margin-left: 2px;
						font-size: 1.2rem;
					}
					// div {
					// 	display: inline-flex;
					// }
					span {
						color: #9a9ab0;
						font-size: 1.3rem;
						font-weight: 700;
						margin-right: 5px;
					}
				}
			}
			.hotelInfoFirst {
				text-align: left;
				p {
					margin-right: 8px;
				}
			}
			.hotelInfoTwo {
			}
			.hotelInfoThree {
			}
			.hotelInfoFour {
				span {
					color: #9a9ab0;
					font-size: 1.1rem;
					font-weight: bold;
					margin-right: 5px;
				}
			}
		}
	}
	.footerButton {
		position: relative;
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		span{
			color:red;
			position: absolute;
			bottom: -20px;
			left: 40px;
			font-size: 1rem;
			font-weight: 400;
		}
		> div {
			&:first-child {
				position: relative;
				display: flex;
				max-width: 50%;
				font-size: 1.3rem;
				font-weight: 600;
				color: #515151;
				cursor: pointer;
				input {
					margin-right: 10px;
					cursor: pointer;
				}
				a {
					color: #0d6efd;
				}
			}
			button {
				background: #f36326;
				padding: 15px 50px;
				border-radius: 15px;
				font-weight: bold;
				color: white;
				transition: all 200ms ease;
				&:hover {
					transform: scale(1.08);
				}
			}
		}
	}
}
.pay {
	display: flex;
	margin-top: 50px;
	.leftBlock,
	.rightBlock {
		position: relative;
		width: 50%;
		padding: 41px 45px 38px;
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		background: #ffffff;
		span{
			color:red;
			position: absolute;
			bottom: 0;
			left: 40px;
		}
	}
	.leftBlock {
		.header {
			h2 {
				text-align: left;
				font-weight: bold;
				font-size: 1.5rem;
				margin-bottom: 20px;
			}
			.price {
				display: flex;
				justify-content: space-between;
				text-align: left;
				font-size: 1.2rem;
				border-bottom: 1px solid #e1e1e1;
				padding-bottom: 10px;
				margin-bottom: 15px;
				p {
					&:first-child {
						font-size: 1.2rem;
						font-weight: 600;
					}
					&:last-child {
						font-size: 1.2rem;
						font-weight: bold;
					}
				}
			}
			.price2 {
				border-bottom: 0!important;
				margin-bottom: 0px;
			}
		}
		.summ {
			display: flex;
			justify-content: space-between;
			p {
				&:first-child {
					font-size: 1.5rem;
					color: #11142d;
				}
				&:last-child {
					font-size: 1.8rem;
					color: #11142d;
					font-weight: bold;
				}
			}
		}
	}
	.rightBlock {
		margin-left: 20px;
		h2 {
			font-size: 1.5rem;
			font-weight: bold;
		}
		form {
			> div {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e1e1e1;
				padding-bottom: 15px;
				margin-top: 15px;
				font-size: 1.2rem;
				line-height: 20px;
				font-weight: 500;
				color: #212529;
				cursor: pointer;
				img {
					height: 27px;
					margin-left: 15px;
				}
				input {
					cursor: pointer;
					margin-right: 10px;
				}
			}
		}
	}
}

.promocode {
	border-top: 1px solid #e1e1e1;
	padding-top: 20px;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		font-size: 1.5rem;
		color: #11142d;
		margin-right: 10px;
	}

	input {
		color: #142030;
		background: #fdfdfd;
		padding: 15px;
		border: 1px solid #e1e1e1;
		width: 100%;
		border-radius: 12px;
		margin-right: 15px;
	}

	input.errorClass {
		border: 1px solid red;
	}

	input.existClass {
		border: 1px solid greenyellow;
	}

	button {
		align-items: center;
		display: flex;
		height: 56px;
		padding-left: 15px;
		padding-right: 15px;
		justify-content: center;
		background-color: #3982cb;
		color: white;
		font-size: 1.3rem;
		font-weight: 600;
		border-radius: 12px;
		transition: all 300ms ease;
	}
}

@media (max-width: 1000px) {
	.promocode {
		button {
			height: auto;
			padding: 10px;
			font-size: 1.3rem;
		}
	}
	.content {
		.card {
			.infoTour {
				display: block;
			}
			h3 {
				width: 100%;
			}
		}
		.header {
			text-align: center;
			h1 {
				font-size: 3.1rem;
				font-weight: 700;
				color: #1d293f;
			}
			p {
				font-size: 1.8rem;
				font-weight: 500;
				color: #546179;
			}
		}
		.infoItem {
			width: 100% !important;
		}
		.picture {
			width: 100% !important;
			img {
				width: 345px !important;
				height: 142px !important;
			}
		}
		.changeItems {
			margin-top: 17px;
			width: 100% !important;
			height: auto !important;
			padding: 10px !important;
			font-size: 1.3rem !important;
			justify-content: center;
			img {
				margin-left: 13px !important;
				width: 24px !important;
			}
		}
		.pay {
			flex-direction: column;
			.leftBlock {
				width: 100%;
			}
			.rightBlock {
				margin-top: 40px;
				width: 100%;
				margin-left: 0;
			}
		}
	}
	.infoItem {
		h5 {
			color: #9a9ab0;
			font-size: 1.25rem;
			font-weight: bold;
			margin-bottom: 10px;
			margin-top: 20px !important;
		}
		p {
			font-size: 1.4rem;
			font-weight: bold;
		}
	}
	.changeItems {
		margin-top: 0px;
	}
}
@media (max-width: 770px) {
	.footerButton {
		flex-direction: column;
		div:first-child {
			align-items: left;
			// width: 100%;
			max-width: 100% !important;
		}
		div:last-child {
			margin-top: 10px;
		}
	}
}
@media (max-width: 577px) {
	.pay {
	.leftBlock {
		.summ {
			p {
				margin-bottom: 10px;
				&:last-child {
					font-size: 1rem;
				}
				&:first-child {
					font-size: 1rem;
				}
			}
		}
	}
}




	.hotelInfoFirst{
		img{
			width: 14px;
			height: 14px;
		}
	}
	.content {
		margin-top: 100px !important;
	}
	.header {
		text-align: center;
		h1 {
			font-size: 1.6rem !important;
			font-weight: 700;
			color: #1d293f;
		}
		p {
			font-size: 1rem !important;
			font-weight: 500;
			color: #546179;
		}
	}
	.card {
		margin-top: 23px !important;
	}
	.promocode {
		flex-direction: column;
		align-items: normal;

		input {
			margin-bottom: 10px;
		}
	}
}
input:checked + .custom_checkbox {
	border-color: #0b76ef;
	background-color: #0b76ef;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom_checkbox {
	cursor: pointer;
	content: '';
	display: inline-block;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid #dce5eb;
	border-radius: 8px;
	margin-right: 21px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	outline: none;

	.active_checkbox {
		width: 100%;
		height: 100%;
		background-color: #0a58ca;
	}
}
