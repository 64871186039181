.getOffer1 {
	// padding-top: 100px;

	.monthContainer {
		display: flex;
		justify-content: center;
		max-width: 865px;
		margin: auto;
		width: 100%;
	}

	color: white;
	.table {
		display: flex;
		flex-wrap: wrap;
		max-width: 900px;
		margin-right: auto;
		margin-left: auto;
		margin-top: 40px;
		padding-bottom: 40px;
		justify-content: space-around;
		padding-right: 8px;
		padding-left: 8px;
		.item {
			width: 30%;
			//margin: 0px 20px 10px 0;
			//text-align: center;
			//border: 2px solid white;
			//padding: 10px;
			//border-radius: 10px;
			//font-size: 1.7rem;
			//font-weight: 500;
			//cursor: pointer;

			cursor: pointer;
			max-width: 275px;
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px;
			flex: 1 1 auto;
			border: 2px solid #FFFFFF;
			border-radius: 12px;
			background-color: transparent;
			font-family: 'Gilroy', sans-serif;
			font-weight: 500;
			font-size: 26px;
			line-height: 31px;
			color: #FFFFFF;
			&:nth-child(2) {
				margin: 20px 20px 0;
			}
		}
		.active {
			background-color: #ffffff;
			color: #3982cb;
		}
	}
	.header {
		text-align: center;
		h1 {
			margin-top: 55px;
			margin-bottom: 10px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #FFFFFF;
			text-align: center;
		}
		h3 {
			width: fit-content;
			opacity: 0.7;
			font-weight: bold;
			font-size: 1.5rem;
			margin: 15px auto 30px auto;
			border-bottom: 2px solid rgba(#ffffff, 0.7);
			padding-bottom: 5px;
		}
	}
}
.picker {
	text-align: center;
}
@media (max-width: 1199.98px) and (min-width: 576px) {
	.getOffer1 {
		.header {
			margin-left: 20px!important;
			margin-right: 20px;
			h1 {
				//margin-left: 30px!important;
				//margin-right: 30px;
			}
		}
	}
}


@media (max-width: 767px) {
	.monthContainer {
		flex-direction: row;
		align-items: center;
	}
	.table {
		margin-left: 15px !important;
		margin-right: 15px !important;
		padding-right: 0!important;
		padding-left: 0!important;

		.item {
			min-width: 30%;
			max-width: 30%;
		}
	}
	.header {
		text-align: left !important;
		h1 {
			margin-left: 15px !important;
			text-align: center;
		}
	}
}
@media (max-width: 707px) {
	.header {
		h1 {
			font-size: 2.2rem;
			margin-left: 40px;
		}
		h3 {
			text-align: left !important;
			font-weight: 100 !important;
			font-size: 1.3rem;
			margin: 15px auto 60px auto;
			margin-left: 40px !important;
		}
	}
}
@media (max-width: 576px) {
	.getOffer1 {
		.header {
			h1 {
				font-size: 30px;
				line-height: 32px;
				margin-left: 40px;
				padding-right: 15px;
				margin-top: 0;
			}
		}
		.table {
			margin-top: 30px;
			.item {
				font-size: 16px;
			}
		}
	}
}
