.slider {
	position: relative;
	margin-bottom: 20px;
	@apply mt-10;
	// nav img{
	//   @apply rounded-lg;
	// }

	.pictureCounter {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 28px;
		right: 24px;
		background: #FFFFFF;
		opacity: 0.8;
		border-radius: 24px;
		padding: 14px 24px;
		min-width: 112px;
		min-height: 48px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #212529;

		.picCountIcon {
			margin-right: 8px;
		}
	}
}

.description {
	h2 {
		@apply text-4xl font-bold;
		margin-left: 2px;
	}

	h3 {
		@apply text-2xl font-bold;
	}

	font-size: 2.2rem;
	padding-bottom: 20px;
	font-weight: bold;
	border-bottom: 2px solid #e9ecef;
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bg {
	background: #fbfbfb;
	padding: 16px 24px 10px 24px;
	border-radius: 8px;

	.price {
		margin-bottom: 15px;

		p {
			color: #99a3ad;
		}

		h3 {
			font-size: 2.5rem;
			font-weight: bold;
			display: flex;
			align-items: center;
		}

		span {
			font-size: 1rem;
			color: #546179;
			font-weight: 500;
			margin-left: 15px;
		}
	}


}

.crumbs {
	margin-left: 2px;

	span {
		color: #99a3ad;
		font-size: 1.3rem;
		font-weight: 500;

		a:hover {
			color: #99a3ad;
		}
	}
}

.description2 {
	margin-left: 20px;
	margin-top: 20px;
	max-width: 50%;

	h2 {
		@apply text-4xl font-bold;

	}
}

.hotelInfo {
	margin-top: 100px;
	margin-bottom: 35px;
	display: flex;

	img {
		border-radius: 32px;
		width: 50%;
		max-height: 380px;
	}
}

@media (max-width: 990px) {
	.hotelInfo {
		display: block;

		img {
			width: 100%;
		}

		.description2 {
			margin-left: 0;
			max-width: 100% !important;

			h2 {
				margin-top: 40px;
				color: black;
				font-size: 1.6rem;
			}
		}
	}

	.infoCard {
		svg {
			color: #546179 !important;
		}
	}
}

@media (max-width: 991.98px) {
	.slider {
		.pictureCounter {
			bottom: 128px;
			right: 8px;
			font-size: 6.04473px;
			line-height: 8px;
			min-width: 42px;
			min-height: 24px;
			padding: 6px 9px;

			.picCountIcon {
				width: 7.6px;
				height: 7.6px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
}

@media (max-width: 576.8px) {
	.bg {
		.price {
			h3 {
				font-size: 1.8rem;
			}
		}
	}
}


.reviews_row_title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.reviews_wrapper_icon_name {
	display: flex;
	align-items: center;
}

.reviews_rating_wrapper {
	border-radius: 10px;
	background: rgba(255, 122, 0, 0.12);
	padding: 6px 12px;
	display: flex;
	align-items: center;
	gap: 4px;
	color: #FF7A00;
	font-family: Inter, sans-serif;
	font-size: 20px;
	font-weight: 700;


	.img_star {
		width: 17px;
		height: 17px;
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path d="M15.5722 8.09554L12.5773 10.7093L13.4745 14.6007C13.5219 14.8041 13.5084 15.017 13.4355 15.2128C13.3627 15.4086 13.2337 15.5785 13.0647 15.7014C12.8958 15.8243 12.6944 15.8946 12.4857 15.9037C12.277 15.9127 12.0703 15.86 11.8913 15.7522L8.49732 13.6936L5.1106 15.7522C4.93168 15.86 4.72495 15.9127 4.51625 15.9037C4.30755 15.8946 4.10614 15.8243 3.9372 15.7014C3.76826 15.5785 3.6393 15.4086 3.56642 15.2128C3.49355 15.017 3.48 14.8041 3.52748 14.6007L4.4233 10.7133L1.42771 8.09554C1.26927 7.95889 1.1547 7.7785 1.09837 7.577C1.04204 7.3755 1.04646 7.16185 1.11107 6.96285C1.17568 6.76385 1.2976 6.58835 1.46155 6.45837C1.6255 6.32838 1.82418 6.2497 2.03267 6.23218L5.98119 5.89018L7.52248 2.21393C7.60297 2.02104 7.73873 1.85626 7.91267 1.74036C8.08661 1.62446 8.29096 1.56262 8.49998 1.56262C8.709 1.56262 8.91334 1.62446 9.08728 1.74036C9.26123 1.85626 9.39699 2.02104 9.47748 2.21393L11.0234 5.89018L14.9706 6.23218C15.1791 6.2497 15.3778 6.32838 15.5417 6.45837C15.7057 6.58835 15.8276 6.76385 15.8922 6.96285C15.9568 7.16185 15.9612 7.3755 15.9049 7.577C15.8486 7.7785 15.734 7.95889 15.5756 8.09554H15.5722Z" fill="%23FF7A00"/></svg>');
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.reviews_author_published_date {
	margin-left: 15px;
}

.reviews_author {
	color: #3D3D3D;
	font-family: Gilroy, sans-serif;
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
}

.reviews_published_date {
	margin-top: 3px;
	color: #3D3D3D;
	font-family: Gilroy, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
}

.reviews_title {
	margin: 12px 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	padding: 0 30px 0 30px;
}

.reviews_txt {
	margin-top: 16px;
	color: #000;
	font-family: Gilroy, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 130%;
}

.reviews_wrapper {
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	overflow: hidden;
}

.reviews_btn_all {
	padding: 10px 20px;
	width: 100%;
	margin-top: 12px;
	line-height: 22px;
	background: #f36326;
	color: white;
	font-size: 1.2rem;
	font-weight: 600;
	border-radius: 10px;
	transition: all 300ms ease;
}

.row_map_review {
	margin-bottom: 35px;
}

.reviews_h2 {
	font-size: 2.25rem;
	line-height: 2.5rem;
	font-weight: 700;
	margin-bottom: 0px;
}

.reviews_h2_description {
	margin: 10px 0 18px;
	color: #546179;
	font-family: Gilroy, sans-serif;
	font-size: 21px;
	font-weight: 500;
	line-height: 120%;
}

.reviews_description_star {
	white-space: nowrap;
	border-radius: 10px;
	background: #F4F5F6;
	display: inline-flex;
	padding: 6px 12px;
	align-items: center;

	color: #546179;
	font-size: 14px;
	font-weight: 500;
}

.reviews_description_star_value {
	color: #FF7A00;
	font-family: Inter, sans-serif;
	font-size: 24px;
	font-weight: 700;
	display: inline-block;
	margin-right: 4px;
}

.swiperContainer {
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;
}

.btn_wrapTxt_hide {
	display: none !important;
}

.btn_wrapTxt {
	font-family: "Gilroy", sans-serif;
	font-style: normal;
	color: #3982cb;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	display: block;
	margin-top: 12px;
}

.swiperContainer {
	overflow: visible;
}

.reviews_wrapper_swiper_button_prev {
	left: var(--swiper-navigation-sides-offset, 0px);
	right: auto;
}

.reviews_wrapper_swiper_button_next {
	right: var(--swiper-navigation-sides-offset, 0px);
	left: auto;
}

.detailed_ratings_row {
	display: flex;
	justify-content: space-between;
}

.detailed_ratings_line {
	width: 100%;
	height: 8px;
	border-radius: 3px;
	background-color: #dce3eb;
	overflow: hidden;
	display: flex;
	justify-content: flex-start;
	flex: 2 1 0;
}

.detailed_ratings_wrapper {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 2px 28px 0px rgba(57, 130, 203, 0.12);
	padding: 20px 28px;

}

.detailed_ratings_wrapper_items {
	border-radius: 8px;
	background: rgba(231, 240, 249, 0.60);
	padding: 4px 0px;
}

.detailed_ratings_item {
	padding: 4px 12px;
	display: flex;
	align-items: center;
	gap: 15px;
	justify-content: center;
}

.detailed_ratings_line_value {
	height: 100%;
	display: inline-block;
	border-radius: 4px;
	background-color: #3982cb;
}

.reviews_item {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 2px 28px 0px rgba(57, 130, 203, 0.12);
	padding: 20px 28px;
}

.reviews_item_small {

	.reviews_title,
	.reviews_txt {
		padding: 0;
	}

}

.detailed_ratings_name_line {
	word-break: break-all;
	font-size: 16px;
	font-weight: 500;
	font-family: Gilroy, sans-serif;
	color: #212529;
	flex: 1 1 0;
	text-align: right;
}

.detailed_ratings_title {
	color: #3D3D3D;
	text-align: left;
	font-family: Gilroy, sans-serif;
	font-size: 21px;
	font-weight: 600;

}

.row_Reviews_DetailedRatings {
	margin-bottom: 100px;
	margin-top: 40px;
	overflow: hidden;
}

@media (max-width: 991.8px) {
	.reviews_item_small {
		margin-top: 40px;
	}

	.reviews_h2 {
		font-size: 1.6rem;
	}

	.detailed_ratings_wrapper {
		margin-top: 10px;
	}

	.reviews_wrapper {
		overflow: visible;
	}
}

.reviews_description_img_star {
	width: 19px;
	height: 19px;
	flex-shrink: 0;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"><path d="M17.4043 8.48911L14.057 11.4104L15.0597 15.7596C15.1128 15.9869 15.0976 16.2249 15.0162 16.4437C14.9348 16.6625 14.7906 16.8525 14.6018 16.9898C14.413 17.1271 14.1879 17.2058 13.9546 17.2158C13.7214 17.2259 13.4903 17.167 13.2904 17.0465L9.49704 14.7458L5.71188 17.0465C5.51191 17.167 5.28086 17.2259 5.0476 17.2158C4.81435 17.2058 4.58924 17.1271 4.40043 16.9898C4.21162 16.8525 4.06748 16.6625 3.98603 16.4437C3.90458 16.2249 3.88944 15.9869 3.9425 15.7596L4.94372 11.4148L1.59571 8.48911C1.41863 8.33639 1.29058 8.13478 1.22762 7.90957C1.16467 7.68437 1.1696 7.44559 1.24181 7.22317C1.31402 7.00076 1.45029 6.80461 1.63353 6.65934C1.81677 6.51406 2.03882 6.42612 2.27184 6.40654L6.68489 6.02431L8.4075 1.91556C8.49746 1.69997 8.6492 1.51581 8.8436 1.38627C9.03801 1.25674 9.2664 1.18762 9.5 1.18762C9.73361 1.18762 9.962 1.25674 10.1564 1.38627C10.3508 1.51581 10.5025 1.69997 10.5925 1.91556L12.3203 6.02431L16.7319 6.40654C16.9649 6.42612 17.187 6.51406 17.3702 6.65934C17.5534 6.80461 17.6897 7.00076 17.7619 7.22317C17.8341 7.44559 17.8391 7.68437 17.7761 7.90957C17.7131 8.13478 17.5851 8.33639 17.408 8.48911H17.4043Z" fill="%23FF7A00"/></svg>');
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 12px;
}

.trip_user_icon {
	position: relative;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background-color: rgba(231, 240, 249, 0.6);
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M7.5 6.5C7.5 8.981 9.519 11 12 11C14.481 11 16.5 8.981 16.5 6.5C16.5 4.019 14.481 2 12 2C9.519 2 7.5 4.019 7.5 6.5ZM20 21H21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H20Z" fill="%233982CB"/></svg>');
	background-size: 24px 24px;
	background-position: center;
	background-repeat: no-repeat;
}

.trip_user_icon.trip_user_icon_home::before {
	display: none;
}

.google_user_icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	border-radius: 50%;

	img {
		width: 100%;
		height: 100%;

	}
}


.trip_user_icon::before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: #FFF;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M5.9958 11.9915C9.30717 11.9915 11.9916 9.30713 11.9916 5.99576C11.9916 2.6844 9.30717 0 5.9958 0C2.68443 0 3.05176e-05 2.6844 3.05176e-05 5.99576C3.05176e-05 9.30713 2.68443 11.9915 5.9958 11.9915Z" fill="%2334E0A1"/><path d="M9.69907 4.82603L10.4226 4.03887H8.81813C8.0149 3.4902 7.04485 3.17065 5.99576 3.17065C4.948 3.17065 3.98065 3.49089 3.17875 4.03887H1.57028L2.29379 4.82603C1.85032 5.23065 1.5723 5.81349 1.5723 6.46064C1.5723 7.6819 2.56245 8.67203 3.78371 8.67203C4.36386 8.67203 4.89242 8.44828 5.287 8.0825L5.99578 8.85425L6.70455 8.08317C7.09914 8.44896 7.62702 8.67203 8.20717 8.67203C9.42843 8.67203 10.4199 7.6819 10.4199 6.46064C10.4206 5.81282 10.1426 5.23 9.69907 4.82603ZM3.78437 7.95723C2.95768 7.95723 2.28778 7.28733 2.28778 6.46064C2.28778 5.63395 2.95769 4.96403 3.78437 4.96403C4.61104 4.96403 5.28095 5.63395 5.28095 6.46064C5.28095 7.28733 4.61104 7.95723 3.78437 7.95723ZM5.99643 6.41709C5.99643 5.4323 5.2803 4.58687 4.33504 4.22578C4.84618 4.01207 5.4069 3.89349 5.99576 3.89349C6.58462 3.89349 7.146 4.01207 7.65716 4.22578C6.71258 4.58754 5.99643 5.43232 5.99643 6.41709ZM8.20785 7.95723C7.38116 7.95723 6.71124 7.28733 6.71124 6.46064C6.71124 5.63395 7.38116 4.96403 8.20785 4.96403C9.03453 4.96403 9.70443 5.63395 9.70443 6.46064C9.70443 7.28733 9.03452 7.95723 8.20785 7.95723ZM8.20785 5.67548C7.7744 5.67548 7.42336 6.02652 7.42336 6.45997C7.42336 6.89339 7.7744 7.24444 8.20785 7.24444C8.64127 7.24444 8.99232 6.89339 8.99232 6.45997C8.9923 6.02719 8.64127 5.67548 8.20785 5.67548ZM4.56884 6.46064C4.56884 6.89407 4.21779 7.24511 3.78437 7.24511C3.35094 7.24511 2.9999 6.89407 2.9999 6.46064C2.9999 6.02719 3.35094 5.67615 3.78437 5.67615C4.21779 5.67548 4.56884 6.02719 4.56884 6.46064Z" fill="black"/></svg>');
	background-size: 12px 12px;
	background-position: center;
	background-repeat: no-repeat;
}

.google_user_icon::before {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: #FFF;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><g clip-path="url(%23clip0_0_631)"><path d="M11.28 6.125C11.28 5.735 11.245 5.36 11.18 5H6V7.13H8.96C8.83 7.815 8.44 8.395 7.855 8.785V10.17H9.64C10.68 9.21 11.28 7.8 11.28 6.125Z" fill="%234285F4"/><path d="M6.00003 11.5C7.48503 11.5 8.73003 11.01 9.64003 10.17L7.85503 8.78505C7.36503 9.11505 6.74003 9.31505 6.00003 9.31505C4.57003 9.31505 3.35503 8.35005 2.92003 7.05005H1.09003V8.47005C1.99503 10.265 3.85003 11.5 6.00003 11.5Z" fill="%2334A853"/><path d="M2.92 7.04503C2.81 6.71503 2.745 6.36503 2.745 6.00003C2.745 5.63503 2.81 5.28503 2.92 4.95503V3.53503H1.09C0.715 4.27503 0.5 5.11003 0.5 6.00003C0.5 6.89003 0.715 7.72503 1.09 8.46503L2.515 7.35503L2.92 7.04503Z" fill="%23FBBC05"/><path d="M6.00003 2.69C6.81003 2.69 7.53003 2.97 8.10503 3.51L9.68003 1.935C8.72503 1.045 7.48503 0.5 6.00003 0.5C3.85003 0.5 1.99503 1.735 1.09003 3.535L2.92003 4.955C3.35503 3.655 4.57003 2.69 6.00003 2.69Z" fill="%23EA4335"/></g><defs><clipPath id="clip0_0_631"><rect width="12" height="12" fill="white"/></clipPath></defs></svg>');
	background-size: 12px 12px;
	background-position: center;
	background-repeat: no-repeat;
}

.row_Reviews_DetailedRatings .reviews_wrapper_swiper_button_prev_r {
	position: static;
	width: 61px;
	height: 61px;
	transition: all .3s ease;
}


.row_Reviews_DetailedRatings .reviews_wrapper_swiper_button_next_r {
	position: static;
	margin-left: 13px;
	width: 61px;
	height: 61px;
	transition: all .3s ease;
}

.row_Reviews_DetailedRatings .reviews_wrapper_swiper_button_next_r::after,
.row_Reviews_DetailedRatings .reviews_wrapper_swiper_button_prev_r::after {
	font-size: 14px;
}

.wrapper_swiper_button_r {
	position: absolute;
	right: calc(var(--bs-gutter-x) * .5);
	display: flex;
	top: 50%;
	transform: translateY(-50%);
}

.btn_link_all {
	display: inline-flex;
	padding: 14px 22px 14px 27px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: #F4F5F6;
	color: #3982CB;
	font-family: Gilroy, sans-serif;
	font-size: 20px;
	font-weight: 600;
	gap: 5px;
}

.btn_link_all::after {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M6 19L19 6M19 6V18.48M19 6H6.52" stroke="%233982CB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-size: contain;
	background-repeat: no-repeat;
}

.btn_link_all:hover {
	color: #3982CB;
}

.wrapper_btn_link_all {
	display: flex;
	gap: 10px;
	cursor: pointer;
	// justify-content: flex-end;
}

.wrapper_btn_link_mob {
	display: none;
}

.title_hotelInfoCard {
	color: #212529;
	font-family: Gilroy, sans-serif;
	font-size: 22px;
	font-weight: 400;
	display: block;
	margin-top: 20px;
}

.wrapper_list_InfoCard {
	margin-top: 12px;
	// display: flex;
	gap: 12px;
	flex-wrap: wrap;
}

.wrapper_list_InfoCard div {
	display: inline;
}

.wrapper_list_InfoCard div:not(:last-child) {
	padding-right: 12px;
	border-right: 1px solid #E1E1E1;
	display: inline;
	margin-right: 12px;
	// white-space: nowrap;
}

.wrapper_btn_filter_reviews {
	display: inline-flex;
	align-items: center;
	gap: 10px;
	margin-left: 18px;
}

.btn_filter_reviews {
	display: inline-flex;
	padding: 9px 22px 9px 22px;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: #F4F5F6;
	color: #3982CB;
	font-family: Gilroy, sans-serif;
	font-size: 20px;
	font-weight: 600;
	transition: all .3s ease;
}

.btn_filter_reviews.active {
	background: #3982CB;
	color: #F4F5F6;
}

.wrapper_reviews_count_btn_filter {
	display: flex;
	align-items: center;
}

@media (max-width: 991px) {
	.wrapper_btn_link_all {
		display: none;
	}

	.wrapper_btn_link_mob {
		flex-wrap: wrap;
		display: flex;
		margin-bottom: 20px;
	}

	.wrapper_reviews_count_btn_filter {
		flex-direction: column;
		gap: 10px;
	}

	.wrapper_btn_filter_reviews {
		margin-left: 0;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;

		.btn_filter_reviews {
			flex: 1 1 0;
			white-space: nowrap;
			font-size: 14px;
		}

		.btn_filter_reviews.all {
			width: 100%;
			flex: 1 1 auto;
		}

	}


	.reviews_description_star {
		width: 100%;
	}

	.wrapper_swiper_button_r {
		display: none;
	}

	.reviews_rating_wrapper {
		border-radius: 4px;
		padding: 4px 6px;
		gap: 2px;
		font-size: 16px;

		.img_star {
			width: 14px;
			height: 14px;
		}
	}

	.reviews_published_date {
		margin-top: 0px;
		font-size: 13px;
	}

	.trip_user_icon {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-size: 20px 20px;
	}

	.reviews_author {
		font-size: 16px;
	}

	.reviews_txt {
		font-size: 15px;
	}

	.reviews_description_star {
		border-radius: 10px;
		display: flex;
		padding: 6px 17px;
		align-items: center;
		justify-content: space-between;
	}


	.reviews_h2_description {
		font-size: 16px;
	}

	.swiperContainer {
		padding-top: 18px;
		padding-bottom: 18px;
	}


	// .wrapper_btn_link_all {
	// 	margin-top: 18px;
	// 	gap: 8px;
	// 	justify-content: space-between;
	// }

	.btn_link_all {
		display: flex;
		flex: 1 1 0;
		font-size: 14px;
		font-weight: 600;
	}


	.detailed_ratings_wrapper {
		padding: 16px;
		row-gap: 16px;
	}

	.detailed_ratings_name_line {
		font-size: 14px;
	}

	.detailed_ratings_line {
		flex: 1.7 1 0;
	}
}


@media (max-width: 576px) {
	.detailed_ratings_line {
		flex: 1.2 1 0;
	}
}

@media (max-width: 330px) {
	.detailed_ratings_line {
		flex: 1.1 1 0;
	}
}

.wrapper_modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(45, 57, 68, 0.20);
	padding: 30px;
	z-index: 10;
	transition: all .3s ease;
	opacity: 0;
	visibility: hidden;
}

.wrapper_modal.wrapper_modal_active {
	visibility: visible;
	opacity: 1;
}

.wrapper_modal_active .bodyModal {
	transform: scale(1);
}

.bodyModal {
	position: relative;
	width: 100%;
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 2px 28px 0px rgba(57, 130, 203, 0.12);
	padding: 28px;
	transform: scale(0);
	transition: all .4s ease;
	max-height: 70%;
	overflow: auto;
	max-width: 700px;

	.reviews_row_title {
		justify-content: flex-start;
		gap: 24px;
	}
}

.bodyModal_close {
	position: absolute;
	top: 28px;
	right: 28px;
	width: 32px;
	height: 32px;
	background-color: transparent;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M24 8L8 24M8 8L24 24" stroke="%233982CB" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
	background-repeat: no-repeat;
	background-size: contain;
}