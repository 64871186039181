ul li {
	list-style: none;
}
ul {
	margin: 0;
	padding: 0;
}

.mobile-menu-wrap {
	display: block;
	background-color: #fbf9f9;
	height: 100vh;
	z-index: 11;
	position: fixed;
	top: 0;
	bottom: 0;
	padding-top: 70px !important;
	.menu_mob_list {
		padding-top: 17px;
		._item {
			margin-bottom: 22px;

			.item_a_menu {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 22px;
				line-height: 24px;
				color: #000000;
				opacity: 0.5;
			}
			.item_a_menu.active {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 26px;
				line-height: 39px;
				letter-spacing: -0.03em;
				border-bottom: 2px solid #000000;
				color: #000000;
				opacity: 1;
				width: fit-content;
			}
		}

		._dop_item {
			margin-top: 36px;
		}
	}
	.visible_dop_item {
    visibility: hidden;
    transition: all 170ms ease;
    transform: translateY(400px);
    opacity: 0;
	}
  .hidden-first-table{
    display: none;
  }
  .visible_dop_item_active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
	}
	.language-change-mobile {
		display: flex;
		font-weight: 500;
		font-size: 1.3rem;
		color: #868f98;
		p {
			cursor: pointer;
			&:last-child {
				margin-left: 10px;
			}
		}
		.language-active {
			color: #3982cb;
		}
	}

	.contacts-wrapper {
		position: absolute;
		bottom: 140px;

		.tel-wrapper a {
			display: flex;
			align-items: center;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 20px;
			color: #3982cb;
		}

		.tel-wrapper svg {
			margin-right: 14px;
		}

		.tel-wrapper.email {
			margin-top: 16px;
			margin-bottom: 30px;
		}

		.language-wrapper {
			display: flex;
			align-items: center;
		}

		.language-wrapper svg {
			margin-right: 14px;
		}

		.language-wrapper a {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 100%;
			color: #868f98;
			margin-right: 10px;
		}
		.language-wrapper a.active {
			color: #3982cb;
		}
	}
}

@media (max-width: 576px) {
	.mobile-menu-wrap.container-xxl {
		padding: 0 15px;
	}
}

.arrowButtonExitHeader {
	font-size: 3rem;
	color: #3982cb;
	margin-top: 45px;
}
