.getOffer6 {
	color: white;

	.modalWrapper {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 100;
		left: 0;
		top: 0;

		.modal {
			position: absolute;
			background-color: white;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			border: 1px solid #E1E1E1;
			border-radius: 8px;
			padding: 20px 45px;
			z-index: 50;
			display: flex;
			flex-direction: column;
			align-items: center;

			.text {
				letter-spacing: 0.005em;
				color: #11142D;
				font-family: "Gilroy";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 120%;
				text-align: center;
				margin-bottom: 20px;
			}
			.button {
				padding: 15px 27px;
				background: #3982CB;
				border-radius: 12px;
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 20px;
				color: #FFFFFF;
			}
		}
	}


	.header {
		text-align: center;
		h1 {
			margin-top: 55px;
			margin-bottom: 10px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #FFFFFF;
			text-align: center;
		}
		h2 {
			//width: fit-content;
			//font-weight: 500;
			//font-size: 1.5rem;
			//margin: 15px auto 60px auto;
			//padding: 0px 25px 5px 25px;

			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			line-height: 30px;
			color: #FFFFFF;
			text-align: center;
		}
	}
	.form {
		text-align: center;
		background: transparent;
		margin-right: auto;
		margin-left: auto;
		margin-top: 40px;
		margin-bottom: 70px;
		//margin: 40px auto 40px auto;
		max-width: 700px;

		.contInput:first-child {
			margin-top: 0;
		}
		.contInput {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 12px;
			label {
				top: -40px;
				left: 30px;
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 120%;
				letter-spacing: 0.005em;
				//color: #11142D;
				color: white;
				text-align: left;
			}
			input {
				margin-top: 8px;
				width: 100%;
				background: #FDFDFD;
				border: 1px solid #E1E1E1;
				border-radius: 12px;
				padding: 16px 16px;
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				display: flex;
				align-items: center;
				font-feature-settings: 'liga' off;
				color: #142030;
			}
		}
	}
	span {
		position: absolute;
		font-size: 0.7rem;
		top: 58px;
		color: red;
		left: 30px;
	}
}

@media (max-width: 767.98px) {
	.getOffer6 {
		.header {
			h1 {
				text-align: left;
			}
		}
		.form {
			text-align: left;
		}
	}
}

@media (max-width: 576px) {
	.getOffer6 {
		.header {
			text-align: left !important;
			margin: 0 15px 0 15px;

			h1 {
				margin-top: 0;
				font-size: 30px;
				line-height: 32px;
			}
			h2 {
				//width: fit-content;
				//font-weight: 100 !important;
				//font-size: 1.3rem;
				//margin: 15px auto 60px auto;
				//padding: 0px 25px 5px 25px;

				text-align: left;
				font-size: 20px;
				line-height: 23px;
				font-weight: 400;
			}
		}
		.form {
			margin-left: 25.5px;
			margin-right: 25.5px;
			border: 1px solid #E1E1E1;
			border-radius: 8px;
			background: #FFFFFF;
			padding: 20px;
			margin-top: 30px;
			.contInput {
				//display: flex;
				//justify-content: center;
				label {
					top: 5px !important;
					left: 30px !important;
					font-size: 18px;
					color: #11142D;
				}
				input {
					border-radius: 10px;
					color: #142030;
					width: 100%;
					border: 1px solid #e1e1e1;
				}
			}
		}
		span {
			position: absolute;
			font-size: 0.7rem;
			top: 78px !important;
			color: red;
			left: 30px;
		}
	}
}
