.row {}

.review-component {
	.carousel-wrapper {
		display: flex;
		flex-direction: row;
		overflow: visible;

		.swiper-pagination {
			display: none;
		}

		.review-slide {
			background: #ffffff;
			height: 100%;
			position: relative;

			display: flex;
			flex-direction: column;
			align-items: center;
			padding-bottom: 76px;

			.content {
				box-shadow: 0px 2px 28px rgba(57, 130, 203, 0.12);
				border-radius: 10px;
				border: 1px solid #e5e7eb;
				height: 100%;

				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 35px 30px;
				padding-bottom: 50px;

				width: 100%;
			}

			.stars {
				display: flex;
				flex-direction: row;

				.star {
					width: 23px;
					height: 23px;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}

			.description {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 28px;
				line-height: 34px;
				text-align: center;
				color: #000000;

				margin-top: 28px;
				margin-bottom: 23px;
			}

			.name {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 24px;
				line-height: 29px;
				text-align: center;
				color: #3D3D3D;
			}

			.type {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 21px;
				text-align: center;
				color: #3D3D3D;
				margin-top: 2px;

				padding-bottom: 32px;
			}

			.person {
				width: 106px;
				height: 106px;
				bottom: 30px; //mobile

				border-radius: 50%;
				background-repeat: no-repeat;
				background-size: cover;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.empty-block {
		background-color: gray;
		height: 70px;
		width: 100%;
		margin-top: 10px;
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.review-component {
	margin-top: 150px;

	.container-xxl {
		max-width: 1600px;
	}

	.block_title {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		line-height: 77px;
		color: #1d293f;
	}

	.block_description {
		margin-top: 7px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 28px;
		line-height: 36px;
		color: #546179;
	}
}

.review-swiper-wrapper {
	.carousel-wrapper {
		// margin-top: 45px;
		padding-left: 15px;
		padding-right: 15px;
	}

	//.container-xxl {
	//  padding-left: 0px;
	//  padding-right: 0px;
	//}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


@media (max-width: 1199.98px) {
	.review-component {
		.container-xxl {
			padding: 0 30px;
		}
	}
}

@media (max-width: 993px) {
	.review-component {
		.swiper-pagination {
			display: block !important;
			margin-top: 20px;
			transform: translateX(-55%) !important;
			bottom: 0px !important;
		}
	}
}

@media (max-width: 992px) and (min-width: 576px) {
	.review-component {
		margin-top: 100px;
	}

	.block_title {
		font-size: 50px;
		line-height: 60px;
	}

	.block_description {
		font-style: 24px;
		line-height: 30px;
	}
}

@media (max-width: 576px) {
	.review-component {
		margin-top: 75px;

		.swordStyleRST {
			justify-content: center;
		}

		.container-xxl.container-xxl {
			padding: 0 15px;
		}

		.feedback_wrapper .block_title {
			text-align: center;
		}

		.block_title {
			font-size: 25px;
			line-height: 32px;
		}

		.feedback_wrapper .block_description {
			// text-align: center;
		}

		.block_description {
			margin-top: 4px;
			font-size: 16px;
			line-height: 20px;
			text-align: center;
		}

		.carousel-wrapper {
			.review-slide {
				.content {
					padding-top: 18px;
					padding-bottom: 40px;
					padding-left: 12px;
					padding-right: 12px;
				}

				.person {
					width: 85px;
					height: 85px;
					margin-bottom: 6px;
				}

				.description {
					font-size: 16px;
					line-height: 18px;
					padding: 0 !important;
					margin-top: 12px;
					margin-bottom: 18px;
				}

				.stars {
					.star {
						width: 14px;
						height: 14px;
					}
				}

				.type {
					font-size: 12px;
					line-height: 14px;
				}

				.name {
					font-size: 12px;
					line-height: 14px;
				}
			}
		}
	}

	.review-swiper-wrapper {
		.carousel-wrapper {
			margin-top: 18px;
		}
	}
}

.swiper_home_rev {
	padding: 45px 0 20px;
}

@media (max-width: 576px) {
	.swiper_home_rev {
		padding: 20px 0 20px;
	}
}