.contacts {

  margin-top: 120px;
  .form_contact_wrapper{
    border: 1px solid #E1E1E1;
    border-radius: 12px;
    padding: 40px 50px;
  }
  .card_contacts_row {
    margin-top: 72px;
    //margin-bottom: 150px;
    justify-content: center;
  }
  .container-xxl {
    max-width: 1600px;
  }
  .contact_title {
    margin-top: 80px;
  }

  .center_title {
    margin-top: 80px;
    text-align: center;
  }

  .block_title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #1D293F;
  }

  .block_description {
    margin-top: 7px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #546179;
  }

  .blog_desc {
    margin-top: 10px;
    text-align: center;
  }

  .contacts_row {
    margin-top: 50px;
  }
  .form_contact {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: 12px;
    padding: 40px 50px;
  }

  .form_name {
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #11142D;
  }

  .input_wpap_contact.first {
    margin-top: 30px;
  }
  .input_wpap_contact {
    margin-top: 25px;

    label {
      display: block;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: 0.005em;
      color: #11142D;
    }

    input, textarea {
      margin-top: 10px;
      width: 100%;
      background: #FDFDFD;
      border: 1px solid #E1E1E1;
      border-radius: 12px;
      padding: 16px 16px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      font-feature-settings: 'liga' off;
      color: #142030;
    }

    textarea {
      resize: none;
      height: 114px;
      font-weight: 400;
    }
  }
  .send_contact {
    margin: auto;
    margin-top: 40px;
    display: block;
    width: 320px;
    height: 64px;
    background: #F36326;
    border-radius: 12px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 1;
    letter-spacing: 0.01em;
    color: #FFFFFF;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  .contact_information {
    padding: 0px 0 0 33px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 160%;
    color: #000000;

    .requisites_title_txt {
      margin-bottom: 8px;
      font-weight: 700;
      color: #1D293F;
    }
    a{
      color:#0d6efd;
      &:hover{
        color: #0a58ca;
      }
    }

    h2 {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 36px;
      line-height: 48px;
      color: #1D293F;
      margin: 0;
    }
    .fw500 {
      font-weight: 500;
    }
  }

  ////////////////////////////////////////////////////////// PARTNERS
  .container_partners {
    margin-top: 150px;
    margin-bottom: 150px;
    overflow: hidden;

    .row__partners {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      img {
        width: 50%;
        max-width: 240px;
        vertical-align: top;
      }
    }
  }
  ////////////////////////////////////////////////////////// QUESTIONS
  .questions_block {
    .still_questions_title {
      font-family: 'Gilroy', sans-serif;
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      letter-spacing: -0.64px;
      color: #000000;
    }
    .block_description {
      margin-top: 7px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
      color: #546179;
    }
    .still_questions_description {
      margin-top: 17px;
      text-align: center;
    }

    .body_card_contacts {
      border-radius: 12px;
      padding: 72px 20px 56px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 56px;
        width: 104px;
        height: 104px;
      }
      .body_card_contacts_title {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: rgba(255, 255, 255, 0.75);
      }
      .body_card_contacts_description {
        margin-top: 27px;
        margin-bottom: 48px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: rgba(255, 255, 255, 0.75);
      }
      .body_card_contacts_a {
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #FFFFFF;
      }
      .body_card_contacts_a:last-child {
        margin-top: 8px;
      }
    }
    .body_card_contacts.for_sale {
      background: #F36326;
    }
    .for_help {
      background: #1D293F;
    }
  }

}

@media (max-width: 576px) {
  .contacts {
    .container_partners {
      margin-top: 75px;
      margin-bottom: 75px;
    }

    .questions_block {
      .still_questions_title {
        font-size: 25px;
        line-height: 32px;
        text-align: left;
      }
      .block_description {
        margin-top: 4px;
        font-size: 16px;
        line-height: 20px;
      }
      .still_questions_description {
        margin-top: 2px;
        text-align: left;
      }

      .body_card_contacts {
        border-radius: 5px;
        padding: 35px 15px 25px;

        img {
          margin-bottom: 20px;
          width: 50px;
          height: 50px;
        }
        .body_card_contacts_title {
          font-size: 20px;
          line-height: 24px;
        }
        .body_card_contacts_description {
          font-size: 16px;
          line-height: 18px;
          margin-top: 18px;
          margin-bottom: 22px;
        }
        .body_card_contacts_a {
          font-size: 16px;
          line-height: 19px;
        }
        .body_card_contacts_a:last-child {
          margin-top: 2px;
        }
      }
      .body_card_contacts.for_sale {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .contacts {
    .card_contacts_row {
      margin-top: 20px;
    }
    .questions_block {
      .body_card_contacts {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .contacts {
    .questions_block {
      .block_description {
        font-style: 24px;
        line-height: 30px;
      }
    }
  }
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////1

@media (max-width: 576px) {
  .contacts {
    .block_title {
      font-size: 25px;
      line-height: 32px;
    }

    .blog_desc {
      margin-top: 8px;
      text-align: left;
    }

    .block_description {
      margin-top: 8px;
      font-size: 16px;
      line-height: 20px;
    }

    .contacts_row {
      margin-top: 25px;
    }

    .form_contact_wrapper.form_contact_wrapper {
      padding: 20px;
    }
    
    .form_name {
      font-size: 22px;
      line-height: 32px;
    }

    .input_wpap_contact.first {
      margin-top: 30px;
    }

    .input_wpap_contact {
      margin-top: 20px;

      label {
        font-size: 18px;
      }

      input {
        margin-top: 8px;
        font-size: 16px;
        line-height: 18px;
      }

      textarea {
        margin-top: 8px;
        font-size: 16px;
        line-height: 18px;
      }
    }

    .send_contact {
      margin-top: 20px;
      width: 100%;
      height: 54px;
      font-size: 22px;
    }

    .contact_information {
      font-size: 20px;
      span{
      position: static;
      color: inherit;
      font-size: inherit;
      }
      h2 {
        font-size: 24px;
        line-height: 24px;
      }
      .requisites_title_txt {
        font-size: 25px;
      }
    }

  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .contacts {
    .block_title {
      font-size: 50px;
      line-height: 60px;
    }

    .block_description {
      font-style: 24px;
      line-height: 30px;
    }
  }
}

@media (max-width: 992px) {
  .contacts {
    .form_contact_wrapper {
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      border-radius: 12px;
      padding: 40px 50px;
    }

    .form_contact {
      background: #FFFFFF;
      border: 0px;
      border-radius: 0px;
      padding: 0px;
    }

    .contact_information {
      padding: 40px 0 0 0px;
    }
  }
}
@media (max-width:1200px) {
  .contacts {
    margin-top: 74px;
  }
}