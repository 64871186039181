.item:last-child {
	margin-bottom: 0;
}

.item {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;

	padding-right: 10.5px;
	padding-left: 10.5px;
	> p {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 28px;
		line-height: 120%;
		letter-spacing: 0.005em;
		color: #FFFFFF;
	}
	.buttons {
		display: flex;
		align-items: center;
		position: relative;
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 2px solid #FFFFFF;
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background-color: transparent;
			transition: all 0.3s ease;
			&:hover {
				background: white;
				color: #3982cb;
			}
		}
		p {
			margin: auto 15px;
			font-size: 1.5rem;
			font-weight: bold;
			width: 20px;
		}
		span {
			font-size: 2rem;
			line-height: 30px;
		}
	}
}
@media (max-width: 577px) {
	.item {
		span {
			position: relative;
			top: 0 !important;
			left: 0;
			color: white;
		}
	}
	.buttons {
		button {
			&:hover {
				background: white;
				span {
					color: #3982cb !important;
				}
			}
		}
	}
}
