.search-box {
	//padding: 15px 30px;4
	//width: 100%;
	//margin-bottom: 1px;
	background-color: white;

	.search-box-title {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 26px;
		letter-spacing: 0.02em;
		color: #212529;
		margin-bottom: 22px;
		border-bottom: 2px solid #f5a623;
		width: fit-content;
	}

	.search-box-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		cursor: pointer;

		.search-box-input {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 111%;
			color: #212529;
			margin: 0 0 0 8px;
		}
		.error{
			position: absolute;
			top: 70px;
			left: 20px;
			color: red;
		}
	}
}
@media (max-width:1200px) {
	.error{
		position: absolute;
		top: 90px !important;
		left: 30px !important;
		color: red;
	}
}
.nights {
	.nightFlex {
		display: flex !important;
		justify-content: space-between;
	}
}
.modalWindow {
	position: fixed;
	width: 100%;
	min-height: max-content;
	border-radius: 0;
	left: 0;
	top: auto;
	bottom: 0;
	background-color: white;
	padding: 20px 40px 20px;
	box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
		0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
	z-index: 100;

	.close_select_body {
		display: flex;
		flex-direction: row;
		justify-content: end;
		padding-bottom: 20px;
	}

	.modalFormContent {
		.text {
			font-family: 'Gilroy', sans-serif;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: #212529;
		}

		//.active {
		//  color: #3982CB;
		//}

		.directionText {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: #212529;
			margin-bottom: 15px;
			cursor: pointer;
			transition: all 0.3s ease;
		}

		.img-done {
			width: 16px;
			height: 16px;
		}
	}

	.nights {
		.description-nights {
			width: max-content;
			margin-top: 1px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #868f98;
		}
	}
	.audits {
		.row_people_counter {
			display: flex;
			justify-content: space-between;
			max-width: 350px;
			align-items: center;
		}

		.line {
			width: 100%;
			margin: 12px 0;
			height: 1px;
			background-color: #dce5eb;
		}

		.title {
			font-family: 'Gilroy', sans-serif;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: #212529;
		}
		.description-text {
			width: max-content;
			margin-top: 1px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #868f98;
		}
	}
	.people_counter_wrapper {
		display: flex;
		width: 74px;
		//margin-left: 54px;
		align-items: center;
		justify-content: space-between;
	}
	.btn_counter_people.minus {
		border: 1.2px solid #a4adb6;
	}
	.btn_counter_people.minus::before {
		content: '';
		display: block;
		width: 8px;
		height: 1.2px;
		background-color: #868f98;
	}

	.btn_counter_people.minus.active {
		border: 1.2px solid #3982cb;
	}
	.btn_counter_people.minus.active::before {
		background-color: #3982cb;
	}

	.btn_counter_people {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #ffffff;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: transparent;
		transition: all 0.3s ease;
	}

	.btn_counter_people.plus {
		border: 1.2px solid #a4adb6;
	}

	.btn_counter_people.plus.active {
		border: 1.2px solid #3982cb;
	}
	.btn_counter_people.plus.active::before {
		background-color: #3982cb;
	}
	.btn_counter_people.plus.active::after {
		background-color: #3982cb;
	}

	.btn_counter_people.plus::before {
		content: '';
		display: block;
		width: 8px;
		height: 1px;
		background-color: #a4adb6;
	}
	.btn_counter_people.plus::after {
		position: absolute;
		content: '';
		display: block;
		width: 8px;
		height: 1px;
		transform: rotate(90deg);
		background-color: #a4adb6;
	}
}

body .flatpickr-wrapper {
	display: block;
}

.search-wrap {
	// background: #ffffff;
	box-shadow: 0px 43px 66px rgb(0 0 0 / 7%);
	border-radius: 16px;
	padding: 30px 50px;
	.form-container {
		display: flex;
		justify-content: space-between;
	}
}

.search-box {
	white-space: nowrap;

	p {
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

@media (max-width: 1199.98px) {
	.search-wrap {
		height: auto !important;
		background: transparent;
		padding: 0;

		.form-container {
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;
		}
	}
	.search-box-title {
		width: 100% !important;
	}
	.search-box {
		display: flex;
		flex-direction: column;
		min-width: 315px;
		flex: 1 1 auto;
		max-width: 315px;
		background: #ffffff;
		border-radius: 16px;
		margin-bottom: 16px;
		padding: 24px 32px;
	}
}

@media (max-width: 992px) and (min-width: 576px) {
	.search-wrap {
		.form-container {
			justify-content: space-evenly;
			width: 100%;
		}
	}
	.first-screen-container {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 0;
		padding-bottom: 0;
	}
}

// @media (max-width: 992px) and (min-width: 576px) {
// 	.search-wrap {
// 		height: auto !important;
// 		.form-container {
// 			justify-content: space-evenly;
// 		}
// 		.searchButton {
// 			margin-bottom: 20px;
// 			width: 100% !important;
// 		}
// 	}
// }

@media (max-width: 706px) {
	.search-wrap {
		//height: auto !important;
		//margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		.form-container {
			.search-box {
				max-width: 100%;
				.search-box-title {
					width: fit-content !important;
				}
			}
			.search-box:nth-child(1) {
				//padding-top: 30px;
			}
		}
	}
	.search-box {
		margin-left: 0 !important ;
	}
	body .search-box.flatpickr-custom-input.flatpickr-custom-input {
		border-radius: 0px;
	}

	.search-box-first-child {
		border-radius: 16px 16px 0 0 !important;
		padding-top: 30px;
	}

	.search-box.search-box-last-child {
		border-radius: 0 0 16px 16px;
		padding-bottom: 30px;
	}

	//.search-box:nth-child(6) {
	//  padding-bottom: 30px;
	//  border-radius: 0 0 16px 16px;
	//}

	.search-box {
		min-width: auto;
		margin-bottom: 0;
		border-radius: 0;
		padding: 15px 30px;
	}

	.search-box.flatpickr-custom-input {
		width: 100%;
	}

	body .flatpickr-wrapper {
		width: 100%;
	}
}

@media ((max-width: 576px)) {
	.search-wrap {
		height: auto !important;
		margin: auto;
		width: 100%;
		display: block;

		.form-container {
			width: 100%;
			.search-box {
				min-width: 0;
				max-width: 100%;
				// width: 200px !important;
				border-radius: 0;
				margin-bottom: 0;
				.search-box-title {
					width: fit-content !important;
					margin-bottom: 17px;
				}
			}
		}
	}

	.search-box {
		margin-left: 0 !important ;
	}
	body .search-box.flatpickr-custom-input.flatpickr-custom-input {
		border-radius: 0px;
		// width: 400px !important;
	}

	.search-box-first-child {
		border-radius: 16px 16px 0 0 !important;
		padding-top: 30px;
	}

	.search-box.search-box-last-child {
		border-radius: 0 0 16px 16px !important;
		padding-bottom: 30px;
	}
}
// @media ((max-width: 706px) and (min-width:580px)) {
// 	.search-wrap {
// 		margin: auto !important;
// 	}

// 	.form-container {
// 		width: 400px;
// 		justify-content: flex-start !important;
// 	}
// }
// @media (max-width: 506px) {
// 	.search-wrap {
// 		.searchButton {
// 			width: 100% !important;
// 		}
// 		.search-box {
// 			max-width: 100% !important;
// 		}
// 	}
// }
@media (min-width: 1022px) {
	.modalWindow.modalWindow {
		transform: translateX(-50%);
		top: 720px;
		left: 200px;
		position: absolute;
		width: 240px;
		background: #ffffff;
		border-radius: 16px;
		padding: 20px 52px;
		box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
			0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
		font-family: 'Gilroy', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 21px;
		color: #212529;
	}
}

@media (min-width: 1200px) {
	.flatpickr-custom-input {
		width: 12% !important;
	}
	.search-wrap {
		background: white;
	}
	.search-box-input {
		max-width: 80% !important;
	}
}

.search-page {
	.search-wrap {
		box-shadow: none;
		border: 1px solid #e9ecef;
	}
}

//.search-box-first-child {
//	width: 20% !important;
//}
//.search-box-two-child {
//	width: 18% !important;
//}
//.search-box-four-child {
//	width: 13% !important; //5
//}
//.search-box-five-child {
//	width: 8% !important;
//}
//.search-box-last-child {
//	width: 20% !important;
//}
.arrowButtonExit {
	position: absolute;
	right: 30px;
	top: 20px;
	font-size: 3rem;
	color: #3982cb;
}
@media ((min-width: 706px) and (max-width:1000px)) {
	.searchButton {
		margin-bottom: 20px;
		width: 50% !important;
		margin: auto !important;
	}
}

@media (max-width: 706px) {
	.flatpickr-wrapper {
		width: 100% !important;
		.flatpickr-custom-input {
			// width: 100% !important;
		}
	}
	//body .form-container {
	//	max-width: 293.33px;
	//}
}

.row_people_counter {
	border-top: 1px solid #dce5eb;
	padding-bottom: 10px;
	padding-top: 13px;
	&:first-child {
		border-top: 0px;
		padding-top: 0px;
	}
}
.first-screen-container .m-auto{
	width: 100%;
}