.searchTours{
  width: 1200px;
  margin: auto;
  margin-top: 180px;
  h1{
    max-width: 1300px;
    font-size: 2.8rem;
    font-weight: bold;
    margin-left: 20px;
    color: #1D293F;
  }
  @media (max-width:1200px) {
    width: auto;
    margin: auto 50px;
  }
}
@media (max-width:1200px) {
  .searchTours{
    margin-top: 130px;
    h1{
      margin-bottom: 40px;
    }
  }
}
@media (max-width: 576px) {
  .searchTours {
    margin-top: 74px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 0;
    margin-left: 0;

    h1 {
      margin-left: 0;
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 25px;
      line-height: 32px;
    }
  }
}
