@media (min-width: 1000px) {
	.image-gallery {}

	.image-gallery-content {
		@apply flex;
		max-height: 750px;
		overflow: hidden;
	}

	.image-gallery-thumbnails-wrapper.left,
	.image-gallery-thumbnails-wrapper.right {
		width: 17%;
	}

	.image-gallery-image {
		// max-height: 100%;
	}

	// .right .image-gallery-slide .image-gallery-image{
	// 	max-height: 100% !important;
	// }
	.image-gallery-thumbnail {
		@apply w-full;
		border: 3px solid transparent;
		border-radius: 12px;
		overflow: hidden;
	}

	.image-gallery-thumbnail:hover {
		border: 3px solid #3982cb;
	}

	.image-gallery-thumbnail.active {
		border-radius: 12px;
		overflow: hidden;
		padding: 0;
		border: 3px solid #3982cb;
		//padding-top: 5px;
	}

	.gallery-thumbnail:focus {
		outline: none;
	}

	.image-gallery-slides {
		border-radius: 8px;

		img {
			max-height: 100% !important;
			object-fit: cover !important;
		}
	}

	.image-gallery-slide-wrapper.left,
	.image-gallery-slide-wrapper.right {
		width: 83%;
		border-radius: 10px;
		overflow: hidden;
		transform: translateZ(0)
	}

	.image-gallery-thumbnail:focus {
		border: 3px solid #3982cb;
	}

	.image-gallery-thumbnail-image {
		border-radius: 8px;
		max-height: 150px;
		object-fit: cover;
		overflow: hidden;
	}

	.image-gallery-thumbnails-wrapper.left,
	.image-gallery-thumbnails-wrapper.right {
		transform: translateZ(0);
		margin: 0;
		margin-right: 12px;
	}
}

@media (max-width: 1000px) {
	.image-gallery-thumbnails .image-gallery-thumbnails-container {
		text-align: left !important;
	}

	.image-gallery-thumbnails-wrapper.left,
	.image-gallery-thumbnails-wrapper.right {
		height: 20%;

		.image-gallery-thumbnail {
			@apply w-full;
			border-radius: 12px;
		}
	}

	.image-gallery-swipe {
		img {
			border-radius: 8px !important;
		}

	}

	.image-gallery-slide-wrapper {
		overflow: hidden;
		border-radius: 8px;
		transform: translateZ(0);
	}

	.image-gallery-slides {
		border-radius: 8px;
	}

	.image-gallery-thumbnail {
		width: 200px;
		height: 114px;
		overflow: hidden;
		border-radius: 8px;
	}

	.image-gallery-thumbnail {
		border-radius: 12px !important;
	}

	.image-gallery-thumbnail.active {
		border-radius: 12px;
		overflow: hidden;
	}

	.image-gallery-thumbnail-image {
		border-radius: 8px;
	}

	.image-gallery-slide-wrapper.left,
	.image-gallery-slide-wrapper.right {
		border-radius: 10px;
		overflow: hidden;
		transform: translateZ(0);
		width: 80%;
	}

	.image-gallery-image {
		max-height: 100% !important;
	}

	// .image-gallery-swipe{
	// 	border-radius: 8px;
	// }
	.image-gallery-content {
		max-height: 750px;
		overflow: hidden;
		// img{
		// 	border-radius: 20px;
		// }
	}
}

.swiperContainer_ReviewTxt .swiper-pagination {
	// bottom: -7px;
	position: static;
	margin: auto;
	margin-top: 12px;
	transform: translateX(0);
}

.swiperContainer_ReviewTxt .swiper-pagination-bullet-active {
	background-color: #3982cb;
}