.getOffer5 {
	color: white;
	.header {
		text-align: center;
		h1 {
			margin-top: 55px;
			margin-bottom: 10px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #FFFFFF;
			text-align: center;
		}
		h2 {
			//margin: 15px auto 60px auto;
			//padding: 0px 25px 5px 25px;

			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			line-height: 30px;
			color: #FFFFFF;
			text-align: center;
		}
	}
  .textarea{
   text-align: center;
   max-width: 500px;
	 margin: 0 auto;
	  padding: 40px 0 40px;
   textarea{
    width: 100%;

    height: 140px;
    resize: none;
    border-radius: 15px;
    color:#142030;
    font-size: 1.2rem;
    margin-right: 40px;
    padding: 17px 12px 15px 20px;
   }
  }
}
@media (max-width: 767.98px) {
	.header {
		h1 {
			text-align: left !important;
		}
	}
}

@media (max-width:576px) {
	.header {
		text-align: left !important;
		
		h1 {
			margin-top: 0!important;
			font-size: 30px!important;
			line-height: 32px!important;
			padding-right: 20px;
			margin-left: 15px;
		}
		h2 {
			text-align: left!important;
			font-size: 20px!important;
			line-height: 23px!important;
			font-weight: 400!important;
			margin-left: 15px;

			//margin: 15px auto 30px auto !important;
			//padding: 0px 25px 5px 15px !important;
		}
	}
	.textarea{
		//margin: 0 15px !important;
		//max-width: 100% !important;
		padding-top: 30px!important;
		margin-right: 15px!important;
		margin-left: 15px!important;
	}
}