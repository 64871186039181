.searchBox {

	@media (max-width: 1150px) {
		.wrapper_children.active {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			padding: 15px;
			display: flex;
			align-items: center;
			z-index: 999;
		}
	}

	.input {
		border: 1px solid #DCE5EB;
		padding: 5px 10px;
		font-size: 1.2rem;
		border-radius: 8px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	>h5 {
		margin-bottom: 10px;
	}

	&:nth-child(1) {
		width: 16.6666667%;
	}

	&:nth-child(2) {
		width: 12%;
	}

	&:nth-child(3) {
		width: 14%;
	}

	&:nth-child(4) {
		width: 12%;

		.input {
			display: none;
		}
	}

	&:nth-child(5) {
		width: 20%;
	}

	&:nth-child(6) {
		width: 26%;
	}

	.inputPrice {
		border: 1px solid gray;

	}

	position: relative;
}

@media (max-width:1150px) {
	.guests {
		position: fixed;
	}
}