//.burger-menu {
//  height: 30px;
//  width: 2em;
//  display: flex;
//  flex-direction: column;
//  align-items: flex-start;
//  justify-content: space-between;
//  cursor: pointer;
//  background-color: #6C757D;
//}
//
//.burger-bar {
//  width: 2em;
//  height: 0.2em;
//  background-color: black;
//  border-radius: 0.5em;
//}
//
///* --------- animations ------- */
///* clicked */
//.burger-bar.clicked:nth-child(1) {
//  transform: rotate(45deg) translate(0.4em, 0.75em);
//  transition: ease-out 0.5s;
//  width: 2.5em;
//}
//
//.burger-bar.clicked:nth-child(2) {
//  transform: scale(0.1);
//  transition: ease-out 0.5s;
//}
//
//.burger-bar.clicked:nth-child(3) {
//  transform: rotate(135deg) translate(-0.4em, 0.75em);
//  transition: ease-out 0.5s;
//  width: 2.5em;
//}
//
///* unclicked */
//.burger-bar.unclicked {
//  transform: rotate(0) translate(0);
//  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
//}

.activeHeaderMobile {
	position: fixed !important;
	top: -180px !important;
	background-color: white !important;
}
.activeHeaderAnimateMobile{
  transition: all 1s ease;
	top: 0 !important;
}
.header-mobile {
	background-color: #fff;
	min-width: 100%;
	border-bottom: 1px solid #e0e4e7;
  z-index: 999;
	position: absolute ;
	top: 0;
	.header-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 74px;

		.logoBlue {
			display: flex;
			width: 170px;
			height: 50px;
		}
	}
}
