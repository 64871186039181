.divLeft {
	position: absolute;
	top: 50%;
	left: 20px;
	z-index: 4;
	text-align: center;
	img {
		margin-left: 20px;
		transform: rotateX(40);
	}

	button {
		width: 56px;
		height: 56px;
		transform: translateY(-50%);
		background: #3982cb;
		border-radius: 100%;
		line-height: 60px;
		transition: all 200ms ease;
		border: 1px solid transparent;
		&:hover {
			background: #3270ae;
		}
	}
	.disabled {
		background: transparent;
		border: 1px solid #546179;
		cursor: not-allowed !important;
		&:hover {
			background: transparent;
		}
	}
}
.divRight {
	position: absolute;
	top: 50%;
	right: 20px;
	z-index: 4;
	text-align: center;

	img {
		margin-left: 23px;
		transform: rotateX(40);
	}
	button {
		width: 56px;
		height: 56px;
		transform: translateY(-50%);
		background: #3982cb;
		border-radius: 100%;
		line-height: 60px;
		transition: all 200ms ease;
		border: 1px solid transparent;
		&:hover {
			background: #3270ae;
		}
	}
	.disabled {
		background: transparent;
		border: 1px solid #546179;
		cursor: not-allowed !important;
		&:hover {
			background: transparent;
		}
	}
}
@media  (max-width:576px) {
	.divLeft, .divRight{
		button{
			width: 30px;
			height: 30px;
		}
		img{
			width: 4px;
		}
	}
	.divLeft{
		img {
			margin-left: 12px;
			transform: rotateX(0);
		}
	}
	.divRight{
		img {
			margin-left: 12px;
			font-size: 1rem;
		}
	}
}