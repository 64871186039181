.paymentSuccessful {
  margin-top: 120px;
  .thank_you_container.err404 {
    .row {
      flex-wrap: wrap;
    }
  }
  .err404 {
    padding: 0;

    .wrapper_404 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 42px;
      width: max-content;
      margin-left: auto;
    }
  }
  .thank_you_container {
    overflow: hidden;
    background-color: #3982CB;

    .row {
      flex-wrap: wrap-reverse;
    }

    .thank_you_photo {
      position: relative;
      background-size: cover;
      height: 100vh;
      max-height: 809px;
    }
    .thank_you_photo::after {
      content: '';
      position: absolute;
      display: block;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 100vh 20vw;
      border-color: transparent transparent #3982CB transparent;
    }
    .err404_photo {
      position: relative;
      background-size: cover;
      height: 100vh;
      max-height: 809px;
    }
    .err404_photo::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 100vh 0 0 20vw;
      border-color: transparent transparent transparent #3982CB;
    }

    .mt140 {
      margin-top: 140px;
    }
    .big_txt {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 160px;
      line-height: 100%;
      color: #FFFFFF;
    }
    .mt20mb50 {
      margin-top: 20px;
      margin-bottom: 50px;
    }
    .big_txt_descript {
      margin-top: 20px;
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 100%;
      color: #FFFFFF;
    }
    .btns_thank_you_wrapper {
      display: flex;
      flex-direction: column;

      .btn_thank_you {
        display: inline-block;
        padding: 18px 56px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 100%;
        text-align: center;
        color: #FFFFFF;
        border: 2px solid #FFFFFF;
        border-radius: 12px;
        background-color: transparent;
      }
      .fill {
        background-color: #ffffff;
        color: #3982CB;
      }

    }
  }
}

@media (max-width: 1270px) and (min-width: 992px) {
  .paymentSuccessful {
    .thank_you_container {
      .big_txt.mt140 {
        font-size: 130px;
      }

      .btns_thank_you_wrapper {
        flex-direction: row;
        .btn_thank_you {
          padding: 18px 18px;
        }
        .btn_thank_you.mr28 {
          margin-right: 20px;
        }
      }

    }
  }
}

@media (max-width: 992px) {
  .paymentSuccessful {
    .err404 {
      .wrapper_404 {
        align-items: center;
        width: auto;
        margin-right: 0;
      }
    }
    .thank_you_container {
      .thank_you_photo::after, .err404_photo::after {
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 105px 100vw 0 0;
        border-color: #3982CB transparent transparent transparent;
      }
      .big_txt.mt140 {
        font-size: 120px;
      }
      .big_txt {
        text-align: center;
      }
      .big_txt_descript {
        text-align: center;
      }
      .btns_thank_you_wrapper {
        flex-direction: column;

        .btn_thank_you {
          .mr28 {
            margin-right: 0px;
          }
        }

        .btn_thank_you:last-child {
          margin-top: 10px;
          margin-bottom: 25px;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .paymentSuccessful {
    .thank_you_container {
      .thank_you_photo {
        max-height: 357px;
      }
      .err404_photo {
        max-height: 357px;
      }
      .big_txt.mt140.mt140.mt140.mt140 {
        font-size: 60px;
        line-height: 74%;
        letter-spacing: -0.03em;
      }
      .mt140 {
        margin-top: 40px;
        margin-bottom: 25px;
      }
      .mt20mb50 {
        margin-top: 25px;
        margin-bottom: 30px;
      }
      .big_txt_descript {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
      }
      .btns_thank_you_wrapper {
        .btn_thank_you {
          font-size: 20px;
          padding: 18px 10px;
        }
      }
    }
  }
}



@media (max-width: 1199px) {
  .paymentSuccessful {
    margin-top: 74px;
  }
}

@media (min-width: 1200px) {
  .thank_you_container {
    .btns_thank_you_wrapper {
      flex-shrink: inherit;
      .btn_thank_you:last-child {
        margin-top: 15px;
        //order: 1;
      }
      .btn_thank_you:first-child {
        margin-top: 15px;
        //order: 2;
      }
    }
  }
}