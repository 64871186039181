.popularTour {
	@apply relative h-44 rounded-2xl text-white;
	height: 100%;
    margin-bottom: 30px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.056) 0%, rgba(0, 0, 0, 0.336) 58.08%, rgba(0, 0, 0, 0.56) 100%);
	.header {
		@apply ml-6 mt-3 pt-5;
		h2 {
			@apply text-3xl font-bold;
		}
	}
	img {
		@apply absolute rounded-2xl z-[-1];
		width: 100%;
		height: 100%;
        object-fit: cover;
	}
	.footer {
		@apply absolute bottom-5 w-full left-6 text-xl;
    .footerButton{
      @apply flex justify-between mr-10 items-center;
      span{
        @apply font-bold;
      }
      .button{
        @apply rounded-full py-2 px-4 flex items-center transition-all duration-100 ease-in;
        background-color: #3982cb;
       svg{
        @apply ml-3 text-3xl transition-all duration-100 ease-in;
       }
       &:hover{
        // transform: scale(1.1);
          color:#3982cb;
          background-color: #fff;
        svg{
          color:#3982cb;
        }
       }
      }
    }
	}
}
@media (max-width:1200px) {
  .popularTour{
    height: 300px !important;
  }
}
@media (max-width: 470px) {
	.popularTour {
    .footer{
      
    }
		.footerButton {
      
			display: block !important;
      p{
        margin-bottom: 10px;
      }
		}
	}
}