@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	padding: 0;
	margin: 0;
	border: 0;
}

main {
	display: block;
}

/* Range Slider */
input[type='range']::-webkit-slider-thumb {
	pointer-events: auto;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 2px solid #3982cb;
	-webkit-appearance: none;
	@apply bg-white;
}

img {
	/* height: unset; */
}

img {
	/* object-fit: cover; */
}

/*@font-face {*/
/*  font-family: "Gilroy";*/
/*  src: local("GilroyRegular"),*/
/*  url("./fonts/gilroy-medium.ttf") format("truetype");*/
/*}*/

@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy Bold'), local('Gilroy-Bold'),
		url('./fonts/bold/Gilroy-Bold.woff2') format('woff2'),
		url('./fonts/bold/Gilroy-Bold.woff') format('woff'),
		url('./fonts/bold/Gilroy-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

/* @font-face {
	font-family: 'GoldmanBold';
	src: local('GoldmanBold'),
		url('./assets/shrifts/Gilroy-Bold.ttf') format('truetype');
	font-weight: 700;
} */
@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy Regular'), local('Gilroy-Regular'),
		url('./fonts/regular/Gilroy-Regular.woff2') format('woff2'),
		url('./fonts/regular/Gilroy-Regular.woff') format('woff'),
		url('./fonts/regular/Gilroy-Regular.ttf') format('truetype');

	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy Medium'), local('Gilroy-Medium'),
		url('./fonts/medium/Gilroy-Medium.woff2') format('woff2'),
		url('./fonts/medium/Gilroy-Medium.woff') format('woff'),
		url('./fonts/medium/Gilroy-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gilroy';
	src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
		url('./fonts/semibold/Gilroy-Semibold.woff2') format('woff2'),
		url('./fonts/semibold/Gilroy-Semibold.woff') format('woff'),
		url('./fonts/semibold/Gilroy-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

body {
	margin: 0;
	font-family: 'Gilroy';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden !important;
}

body.no_scroll {
	overflow: hidden !important;
}

html.no_scroll {
	overflow: hidden !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.mainBg {
	/*background-color: #61dafb;*/
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		url('./assets/images/cover.webp');
	background-size: cover;
	padding-top: 121px;
}

@media (max-width: 706px) {
	.mainBg2 {
		/*background-color: #61dafb;*/
		background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
			url('./assets/images/cover.webp');
		background-size: cover;
	}

	.inviteComp2 {
		margin-top: 0;
		padding-top: 120px !important;
	}

	.search-wrap {
		border: none;
	}

	.title-first-screen {
		color: white;
	}
}

@media (max-width: 1199.98px) {
	.mainBg {
		padding-top: 74px;
	}
}

.container-xxl {
	max-width: 1600px;
}

.flatpickr-calendar.flatpickr-calendar.flatpickr-calendar .animate .arrowTop .arrowLeft .open {
	position: fixed;
	width: 100%;
	min-height: max-content;
	border-radius: 0;
	left: 0;
	top: auto;
	bottom: 0;
}

/*0 - 576*/
.container-xxl2 {
	padding-right: 15px;
	padding-left: 15px;
}

/*576 - 768*/
@media (min-width: 576px) {
	.container-xxl2 {
		width: 100%;
		padding-right: 30px;
		padding-left: 30px;
		margin-right: auto;
		margin-left: auto;
	}
}

/*768 - 992*/
@media (min-width: 768px) {
	.container-xxl2 {
		width: 100%;
		padding-right: 30px;
		padding-left: 30px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (min-width: 992px) {
	.container-xxl2 {
		width: 100%;
		padding-right: 30px;
		padding-left: 30px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (min-width: 1200px) {
	.container-xxl2 {
		width: 100%;
		padding-right: 10.5px;
		padding-left: 10.5px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media (min-width: 1400px) {
	.container-xxl2 {
		width: 100%;
		padding-right: 40px;
		padding-left: 40px;
		margin-right: auto;
		margin-left: auto;
	}
}

.emailError {
	border: 1px solid red !important;
}

.Toastify .Toastify__toast-container .Toastify__toast {
	margin-bottom: 10px;
}

.Toastify .Toastify__toast-container .Toastify__toast:first-child {
	margin-top: 75px;
}

.Toastify .Toastify__toast-container--top-right {
	margin-right: 25px;
}

.wrapperCheckDay {
	position: absolute;
	left: 50%;
	bottom: 10px;
	transform: translateX(-50%);
}

.wrapperCheckDay:not(:last-child) {
	display: none;
}

.wrapperCheckDay .input_checkbox {
	width: 20px;
	height: 20px;
	border: 1px solid #DCE5EB;
	border-radius: 5px;
	transition: all .3s ease;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
}

.wrapperCheckDay .input_checkbox.checked {
	background-color: #3982CB;
	border-color: #3982CB;
	background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8"><path fill="%23fff" d="M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z"/></svg>');
	background-size: 11px;
}

.wrapperCheckDay .wrapper_body_fp {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 500;
	font-size: 14px;
}

.form-container .flatpickr-innerContainer,
.box-custom .flatpickr-innerContainer {
	padding-bottom: 50px;
}

.form-container .flatpickr-calendar {
	position: absolute;
	height: max-content;
}

html body .flatpickr-calendar.static {
	height: max-content;
}