.blogId {
	max-width: 1600px;
	margin: auto;
	margin-top: 150px;

	img {
		margin: auto;
		max-height: 530px;
		height: 530px;
		width: calc(100% + 40px);
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		max-width: none;
		object-fit: cover;
	}

	.infoPost {
		margin-top: 90px;
		margin-bottom: 35px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.date_blogid {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 20px;
			display: flex;
			align-items: center;
			color: #99A3AD;
		}

		.link_blogid {
			font-family: 'Gilroy', sans-serif;
			font-weight: 500;
			font-size: 20px;
			line-height: 20px;
			color: #99A3AD;
		}
	}

	h1 {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 2px solid #e9ecef;

		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 42px;
		line-height: 48px;
		color: #1D293F;
	}

	h2 {
		font-family: 'Gilroy', sans-serif;
		font-weight: 600;
		font-size: 28px !important;
		line-height: 48px;
		color: #1D293F;
		margin-bottom: 20px;
	}

	@media (max-width: 1640px) {
		margin-left: 20px;
		margin-right: 20px;
	}

	.description {

		p,
		b,
		span {
			margin-bottom: 15px;
			font-family: "Gilroy", sans-serif !important;
			font-size: 22px !important;
			line-height: 120%;
			color: #1D293F;
		}

		h2>*,
		h2 {
			font-family: "Gilroy", sans-serif !important;
			font-size: 36px !important;
			line-height: 120%;
			color: #1D293F;
		}

		h3>*,
		h3 {
			font-family: "Gilroy", sans-serif !important;
			font-size: 32px !important;
			line-height: 120%;
			color: #1D293F;
		}

		h4>*,
		h4 {
			font-family: "Gilroy", sans-serif !important;
			font-size: 26px !important;
			line-height: 120%;
			color: #1D293F;
		}
	}

	@media (max-width:980px) {
		.img {
			text-align: center;
			margin: 0;

			img {
				// max-width: 99%;
			}
		}
	}
}

@media (max-width:1200px) {
	.blogId {
		margin-top: 100px;
	}
}

@media (max-width: 576px) {
	.blogId {
		img {
			max-height: 330px;
			height: 330px;
		}

		.infoPost {
			margin-top: 18px;
			margin-bottom: 10px;
			flex-direction: column;
			align-items: start;

			.date_blogid {
				font-size: 16px;
				line-height: 20px;
			}

			.link_blogid {
				font-size: 16px;
				line-height: 20px;
				margin-bottom: 15px;
			}
		}

		h1 {
			font-size: 30px;
			line-height: 36px;
		}

		h2 {
			font-size: 24px !important;
			line-height: 24px !important;
		}


		.description {

			p,
			b,
			span {
				font-size: 14px !important;
			}

			h2>*,
			h2 {
				font-size: 24px !important;
			}

			h3>*,
			h3 {
				font-size: 20px !important;
			}

			h4>*,
			h4 {
				font-size: 18px !important;
			}
		}


	}
}