.nights {
	display: flex;
	position: absolute;
	justify-content: space-between;
	align-items: center;
	width: 200%;
	border-radius: 15px;
	top: 92px;
	left: -50%;
	background: white;
	padding: 15px 25px;

	box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
		0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);

	.header {
		p {
			&:first-child {}

			&:last-child {
				font-size: 0.7rem;
				color: #868f98;
			}
		}
	}

	.buttons {
		display: flex;

		.disabled {
			border: 1px solid gray;
			color: gray;
		}

		button {
			border-radius: 100%;
			border: 1px solid #3982cb;
			width: 20px;
			height: 20px;
			line-height: 20px;
			color: #3982cb;
		}

		p {

			width: 20px;
			margin: 0 5px 0 5px;
			text-align: center;
		}
	}

}

.styleButtonArrow {
	display: none;
}

@media (max-width:1400px) {
	// .nights{
	// 	left: -30% !important;
	// }
}

@media (max-width:1150px) {

	.nights {
		position: relative;
		border-radius: 16px;
		left: 0;
		top: 0;
		width: 100%;
		height: fit-content;
		z-index: 9999;
		padding: 38px 24px 24px 24px;

	}

	.styleButtonArrow {
		display: block !important;
		position: absolute;
		top: 10px;
		right: 10px;
	}
}