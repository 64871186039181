.content {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 65px 0 103px;
	background-color: #0D799A;
	background-size: cover;
	min-width: 100%;
}
.title_first{
	font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 116%;
    color: #FFFFFF;
    text-align: center;
	margin-bottom: 40px;
}

@media (min-width: 1200px) {
	.margin {
		margin-top: 120px;
	}
}
@media (max-width: 1200px) {
	.margin {
		margin-top: 74px;
	}
}

@media (max-width: 576px) {
	.content {
		padding: 30px 0 70px;
	}
	.title_first{
		font-weight: 700;
		font-size: 28px;
		line-height: 28px;
		letter-spacing: -0.02em;
		margin: 12px 0 25px;
		text-align: center;
	}
}