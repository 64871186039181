.popular-destinations {
  margin-top: 75px; //mobile
  h3.block_title {
    margin-top: 80px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #1D293F;
  }

  p.block_description {
    margin-top: 7px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #546179;
  }

  .slide-wrapper {
    padding-top: 18px;
    padding-bottom: 45px;
    .image {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 212px;
      border-radius: 15px;
      padding: 15px;
      background-repeat: no-repeat;
      background-size: cover;

      .name {
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #FFFFFF;
      }

      .hotels {
        margin-top: 2px;
        font-family: 'Gilroy', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
      }

    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (max-width: 576px) {
  .popular-destinations {
    h3.block_title {
      font-size: 25px;
      line-height: 32px;
      margin-top: 30px!important;
    }
    p.block_description {
      margin-top: 4px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .popular-destinations {
    h3.block_title {
      font-size: 50px;
      line-height: 60px;
    }
    p.block_description {
      //font-style: 24px;
      line-height: 30px;
    }

    .slide-wrapper {
      .image {
        height: 300px;
        background-position: center;
      }
    }
  }
}
@media (max-width:1000px) {
  .popular-destinations {
    margin-top: 45px; //mobile
    h3.block_title {
      margin-top: 40px;
    }
  }
}