.custom-picker-offer {
	.flatpickr-wrapper {
		margin: auto;
		width: fit-content !important;
		//margin-bottom: 40px;
		margin-top: 12px;

		.flatpickr-months {
			padding: 0;
		}

		.flatpickr-calendar.static {
			top: 50px;
			left: -60px;
		}

		.flatpickr-next-month,
		.flatpickr-prev-month {
			height: auto;
			width: auto;
		}
	}

	.custom-picker-header {
		border-bottom: 2px solid white;
		font-size: 1.6rem;
	}

	.custom-picker-offer-text {
		opacity: 0.7;

		p {
			//font-size: 1.6rem;
			//font-weight: 500;

			display: inline-block;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 26px;
			line-height: 31px;
			text-align: center;
			border-bottom: 2px solid #FFFFFF;
			margin: auto;
			color: #FFFFFF;
			cursor: pointer;
		}
	}

	.search-box-wrapper {
		img {
			width: auto;
		}
	}
}

@media (max-width: 1199px) {
	.custom-picker-offer .flatpickr-calendar.static {
		border-radius: 0 !important;
		position: fixed !important;
		bottom: 0 !important;
		left: 0 !important;
		width: 100%;
		top: auto !important;
		transform: none;


		padding: 0;


		.flatpickr-months::before {
			display: none;
		}

		.flatpickr-innerContainer {
			border-radius: 0;
		}

		&.animate.open {
			-webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
			animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
		}
	}

}

@media (max-width: 707px) {
	.custom-picker-offer .flatpickr-calendar.static {
		position: fixed !important;
		bottom: 0 !important;
		left: 0 !important;
		width: 100%;
		top: auto !important;
	}

	.custom-picker-offer {
		margin-left: 15px !important;

		.flatpickr-wrapper {
			margin-left: 0;
			//margin-bottom: 40px;
		}
	}
}

@media (max-width: 576px) {
	.custom-picker-offer {
		.custom-picker-offer-text p {
			font-size: 20px;
			line-height: 23px;
			cursor: pointer;

		}

		.flatpickr-wrapper {
			margin: 10px auto 0;
		}
	}

	.custom-picker-offer .flatpickr-wrapper {
		margin-top: 10px;
	}
}