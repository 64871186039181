.mealTypes {
	display: flex;
	position: absolute;
	justify-content: space-between;
	align-items: center;
	border-radius: 15px;
	width: 100%;
	top: 92px;
	left: 0px;
	background: white;
	padding: 15px;
	font-size: 1.1rem;
	box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
		0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);

	li {
		align-items: center;
		margin-left: 18px;
		position: relative;
		margin-bottom: 15px;
		cursor: pointer;

		&:hover {
			color: #3982cb;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.checkMark {
			position: absolute;
			left: -18px;
			top: 2px;
		}
	}

	.active {
		color: #3982cb;
	}

	button {
		display: none;
	}
}

@media (max-width: 1150px) {
	.mealTypes {
		padding: 38px 24px 24px 24px;
		position: relative;
		// bottom: 0px !important;
		border-radius: 16px;
		left: 0;
		top: 0;
		width: 100%;
		height: fit-content;
		z-index: 9999;

		button {
			display: block;
		}
	}
}