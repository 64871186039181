.price{
  position: relative;
  display: flex;
  border: 1px solid #DCE5EB;
  padding: 5px 10px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  input{
   width: 100%;
   cursor: pointer;
    &:focus-visible{
      outline: none;
      
    }
  }
  
  
}
.price::before{
  content: "€";
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: #99A3AD;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}