html body .flatpickr-calendar.static {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	justify-content: center;
	background-color: white;
	top: auto;
}

body {
	.flatpickr-calendar:after {
		display: none;
	}

	.flatpickr-calendar.arrowTop:before {
		display: none;
	}

	.flatpickr-calendar.arrowTop:after {
		display: none;
	}

	.flatpickr-months {
		padding-top: 25px;
		padding-bottom: 20px;

		.flatpickr-month {
			background-color: white;
			border-radius: 0;

			.flatpickr-current-month {
				padding: 11px 0 0 0;
			}
		}
	}

	.flatpickr-days {
		border: none;
	}

	.flatpickr-weekdays {
		background-color: white;

		.flatpickr-weekday {
			background-color: white;
			color: #3982cb;
			cursor: default;
			font-size: 15px;
			line-height: 17.58px;
			margin: 0;
			text-align: center;
			display: block;
			-webkit-box-flex: 1;
			flex: 1;
			font-weight: 400;
			font-family: 'Gilroy', sans-serif;
		}
	}

	.numInputWrapper {
		color: #212529;
		pointer-events: none;
		font-family: 'Gilroy', sans-serif;
		font-weight: 500;
		font-size: 22px;
		height: auto;
		line-height: 22px;
	}

	.flatpickr-day {
		height: 28px;
		font-weight: 500;
		font-size: 15px;
		color: #333333;
		line-height: 28px;
	}

	.flatpickr-day.selected {
		background: #3982cb;
		font-weight: 400;
		border-color: #3982cb;
		box-shadow: none;
		color: #fff;
	}

	.flatpickr-day.nextMonthDay {
		font-weight: 500;
		color: #333333;
	}

	.flatpickr-day.nextMonthDay.selected {
		background: #3982cb;
		font-weight: 400;
		border-color: #3982CB;
		box-shadow: none;
		color: #fff;
	}

	.flatpickr-day.flatpickr-disabled {
		color: rgba(72, 72, 72, 0.1);
	}

	.flatpickr-innerContainer {
		padding-bottom: 25px;
		border-bottom: none;
		margin-top: -1px;
	}

	.flatpickr-innerContainer {
		justify-content: center;
	}

	.flatpickr-next-month img {
		margin-top: 32px;
		margin-right: 35px;
		width: 8px;
	}

	.flatpickr-prev-month img {
		margin-top: 32px;
		margin-left: 35px;
		width: 8px;
	}

	select.flatpickr-monthDropdown-months.flatpickr-monthDropdown-months {
		background-color: white;
		appearance: none;
		pointer-events: none;
		color: #212529;

		font-family: 'Gilroy', sans-serif;
		font-weight: 500;
		font-size: 22px;
		height: auto;
		line-height: 22px;
		margin: -1px 0 0 0;
		outline: none;
		padding: 0 0 0 0;
	}
}

@media (min-width: 1199.98px) {
	html body .flatpickr-calendar.static {
		max-width: 368px;
		//max-height: 640px;
		border-radius: 16px;
		padding-bottom: 7px;
		position: absolute;
		top: 120px;
		left: -80px;
		justify-content: center;
		background-color: white;
		// height: 100%;
		// border-bottom-right-radius: 0;
		// border-bottom-left-radius: 0;

		.flatpickr-months {
			padding-top: 20px;
		}

		.flatpickr-innerContainer {
			border-bottom-right-radius: 16px;
			border-bottom-left-radius: 16px;
		}
	}

	.flatpickr-wrapper {
		width: 12% !important;

		.flatpickr-custom-input {
			width: 100% !important;
		}
	}

	//body .flatpickr-innerContainer {
	//    padding-bottom: 0;
	//}
}

@media ((min-width: 1000px) and (max-width:1200px)) {
	html body .flatpickr-calendar.static {
		position: absolute;
		border-radius: 16px;
		top: 127px;
		width: 120%;
		right: 0;
		left: auto;
		bottom: auto;
		padding: 0 10px 0 10px;

		.flatpickr-innerContainer {
			border-bottom-right-radius: 16px;
			border-bottom-left-radius: 16px;
		}
	}

}

.error {
	color: red;
}


@media (min-width: 1001px) {
	.flatpickr_bg_mob {
		display: none;
	}
}

@media (max-width: 1000px) {
	html body .flatpickr-calendar.static {
		width: 100%;
	}

	.flatpickr_bg_mob {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 998;
	}

	.flatpickr-calendar.animate.open {
		animation: none;
	}

	html body .flatpickr-calendar.static {
		position: fixed;
		bottom: auto;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		// width: 100%;
		// height: 100vh;
		// max-height: none;
		justify-content: center;
		align-items: center;
		background-color: transparent;

		display: flex !important;
		align-items: center;
		flex-direction: column;
		padding: 0 15px;


		.flatpickr-months {
			width: 100%;
			position: relative;
			background-color: #fff;
			border-radius: 16px 16px 0 0;

			&::before {
				top: -10px;
				right: 0px;
				transform: translateY(-100%);
				content: '';
				width: 24px;
				height: 24px;
				display: block;
				position: absolute;
				background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18 6L6 18M6 6L18 18" stroke="%233982CB" stroke-opacity="1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
				background-size: contain;
				background-repeat: no-repeat;
				pointer-events: none;
			}
		}

		.flatpickr-innerContainer {
			width: 100%;
			border-radius: 0 0 16px 16px;

		}

	}

}