.table {
	//margin-top: 50px;
	padding-right: 10.5px;
	padding-left: 10.5px;
	div {
		padding: 20px 24px 72px;
		max-height: initial;
		overflow: visible;
	}

	p {
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #BCF0EC;
		text-align: center;
		background: #3982cb;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		display: block;
		flex-shrink: 0;
		margin-right: 16px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		span{
			opacity: 0;
			pointer-events: none;
		}
	}
	>div{
		border: 2px solid #f8f9fa;
		margin-bottom: 45px;
	}
	h2 {
		display: flex;
		background-color: #fbfbfb;
		padding: 24px 34px 24px 15px;
		align-items: center;
		border-bottom: 1px solid #e9ecef;
		border-radius: 15px 15px 0 0 ;

		font-family: 'Gilroy', sans-serif;
		text-transform: uppercase;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 20px;
		color: #212529;
		span {
			color: #bcf0ec;
			text-align: center;
			background: #3982cb;
			border-radius: 100%;
			width: 60px;
			height: 30px;
			line-height: 30px;
			font-weight: bold;
			margin-right: 10px;
		}
	}
	ol {
		list-style-type: decimal;
		padding-left: 2rem;

		li {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
			letter-spacing: 0.5px;
			color: #1D293F;
		}
	}
	div {
	}
}

@media (max-width: 576px) {
	.table {
		div {
			font-size: 13.537px;
			line-height: 18px;
		}
		h2 {
			font-size: 15px;
			line-height: 18px;
			padding: 17px 24px 17px 17px;

			p {
				width: 22px;
				height: 22px;
				min-width: 22px;
				font-size: 12.0329px;
				line-height: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
		ol {
			li {
				font-size: 13.537px;
				line-height: 18px;
			}
		}
	}
}
