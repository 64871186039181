.tableList {
	margin-left: 3px;
	.offerListLoading {
		display: flex;
		justify-content: center;
		text-align: center;
		margin: 20px auto 20px auto;
		font-size: 3rem;
		font-weight: bold;
		img{
			margin-left: 10px;
			width: 40px;
		}
	}
	.externalUl {
		border: 1px solid #3982cb;
		border-radius: 30px;
		li {
			list-style-type: none;
			&:last-child {
				ul {
					border-bottom: none;
				}
			}
		}

		.insideUl {
			display: flex;
			justify-content: space-between;
			background: #3982cb;
			color: white;
			font-weight: 500;
			text-align: center;
			font-size: 1.5rem;
			align-items: center;
			&:first-child {
				border-radius: 30px 30px 0 0;
			}
			li {
				padding: 20px 0 20px 0;
			}
			li:first-child {
				width: 16.666666667%;
				line-height: 20px;
			}
			li:nth-child(2) {
				width: 17%;
				padding: 0;
				line-height: 23px;
			}
			li:nth-child(3) {
				width: 25%;
			}
			li:nth-child(4) {
				width: 16.666666667%;
			}
			li:nth-child(5) {
				white-space: nowrap;
				width: 15%;
				transform: translateX(20px);
			}
			li:nth-child(6) {
				width: 170px;
			}
		}

		.insideUlList {
			display: flex;
			text-align: center;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #e0e0e0;
			li {
				list-style-type: none;
				font-size: 1.4rem;
			}
			li {
				padding: 20px 0 20px 0;
			}
			li:first-child {
				width: 16.666666667%;
			}
			li:nth-child(2) {
				width: 17%;
			}
			li:nth-child(3) {
				width: 25%;
			}
			li:nth-child(4) {
				width: 16.666666667%;
			}
			li:nth-child(5) {
				width: 15%;
			}
			li:nth-child(6) {
				width: 170px;
				button {
					transform: translateX(-25px);
					background: #3982cb;
					color: white;
					// padding: 10px 25px;
					width: 141px;
					height: 52px;
					margin-left: 10px;
					border-radius: 10px;
					font-size: 1.3rem;
					font-weight: 600;
					transition: all 300ms ease;
					&:hover {
						transform: translateX(-25px) scale(1.07);
					}
				}
			}

			.order {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 700;
				font-size: 1.6rem;
			}
		}

		// ul{
		// 	li{
		// 	// border-bottom: 0px;
		// 	}
		// }
	}
}
@media (min-width: 990px) {
	.externalUl2 {
		display: none;
	}
}
@media (max-width: 990px) {
	.externalUl {
		display: none;
	}
	.externalUl2 {
		> li {
			border: 1px solid #3982cb;
			margin-bottom: 30px;
			border-radius: 20px;
			overflow: hidden;
			font-size: 1.4rem;
		}
		.order {
			font-weight: 700;
			font-size: 1.6rem;
		}
		.insideUlList2 {
			display: flex;
			flex-wrap: wrap;

			li {
				width: 50%;
			}
			li:first-child {
				border-right: 1px solid #dce5eb;
				padding: 20px;
				.trip {
					margin-top: 7px;
					display: flex;
					align-items: center;
					p {
						font-size: 0.9rem;
						margin-left: 9px;
						line-height: 14px;
					}
				}
			}
			li:nth-child(2) {
				margin-top: 20px;
				text-align: center;
				button {
					margin-top: 15px;
					padding: 10px 25px;
					background: #3982cb;
					color: white;
					font-size: 1.3rem;
					border-radius: 8px;
					font-weight: 600;
				}
			}
			li:nth-child(3) {
				border-right: 1px solid #dce3eb;
				background-color: #dce3eb;
				text-align: center;
				padding: 5px 0 5px 0;
			}
			li:nth-child(4) {
				border-left: 1px solid #dce3eb;
				background-color: #dce3eb;
				text-align: center;
				padding: 5px 0 5px 0;
			}
		}
	}
}
@media (max-width: 580px) {
	.externalUl2 {
		.insideUlList2 {
			display: flex;
			flex-wrap: wrap;

			li:first-child {
				padding-bottom: 12px;
				font-size: 1rem;
				line-height: 15px;

				.trip {
					p {
						width: calc(100% - 23px);
					}
					img {
						width: 24px;
						height: 24px;
						margin-left: 2px;
					}
					span {
					}
				}
			}
			li:nth-child(2) {
				font-size: 1.1rem;
				font-weight: 500;
				button {
					margin-top: 10px;
					font-size: 0.9rem;
					padding: 6px 20px;
				}
			}
			li:nth-child(3) {
				align-items: center;
				font-size: 0.9rem;
				img {
					width: 24px;
					height: 24px;
				}
			}
			li:nth-child(4) {
				align-items: center;
				font-size: 0.9rem;
				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
}
