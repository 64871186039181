.getOfferSearchBox {
	text-align: center;
	width: fit-content;
	margin: auto;
	color: white;
	position: relative;
	padding: 40px 0 40px;

	.button {
		max-width: 700px;
		border: 2px solid white;
		font-size: 26px;
		line-height: 31px;
		font-weight: 500;
		padding: 14px 22px 14px 32px;
		border-radius: 12px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;

		svg {
			margin-left: 15px;
			flex-shrink: 0;
			//width: 16px;
			//height: 10px;
		}
	}

	.exit {
		display: none;
	}

	.table {
		position: absolute;
		top: 80px;
		border-radius: 10px;
		width: 100%;
		background: white;
		color: black;
		padding: 20px;
		text-align: left;
		font-size: 1.1rem;
		box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
			0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);

		li {
			padding: 0 0 10px;
			cursor: pointer;

			&:hover {
				color: #3982cb;
			}
		}

		.active {
			color: #3982cb;
		}
	}

	.itemTable {
		display: flex;
		align-items: center;
	}
}

// @media (max-width: 577px) {
// 	.getOfferSearchBox {

// 	}
// }
@media (max-width: 577px) {
	.getOfferSearchBox {
		position: relative;
		width: auto;
		margin: 0 25.5px;
		padding-top: 30px;

		.button {
			padding: 0;
		}

		.itemTable {
			justify-content: center;
			padding-right: 20px;
			padding: 14px 68px 14px;
			font-size: 26px;
			line-height: 31px;
			font-style: normal;
			font-weight: 500;
		}

		.table {}

		.div {
			width: 100%;
		}

		.class {
			width: 100%;
		}

		.button {
			width: 100%;
		}

		svg {
			position: absolute;
			right: 10px;
		}
	}
}

@media (max-width:1021px) {
	.table {
		padding-top: 40px !important;
		border-radius: 0 !important;
		position: fixed !important;
		bottom: 0px !important;
		left: 0 !important;
		top: auto !important;
		width: 100%;
	}

	.exit {
		display: block !important;
		position: absolute;
		right: 20px;
		width: 60px;
		height: 30px;
		top: 13px;

		img {
			object-fit: none;
		}
	}
}