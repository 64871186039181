html {
	height: 100%;
}

.slideComp {
	width: 100%;

	.swiper {
		border-radius: 20px;
	}

	cursor: pointer;

	.imageSwiperWrapper {
		max-width: 100%;
	}

	.swiper-wrapper {
		//height: 300px;
		// max-height: 300px;
		// height: 100%;
		// object-fit: scale-down;

	}

	.hotel-stars {
		display: flex;
		flex-direction: row;

		.hotel-star {
			// mobile class
			width: 23px;
			height: 23px;
		}
	}

	.image-slider-wrapper {
		position: relative;

		//max-height: 300px;
		// height: 100%;
		.img-slider {
			//border-radius: 20px;
			//display: block;
			// width: 100%;
			// height: 30%;
			// height:300px;

			// max-width: fit-content;
			// max-height: 300px;
		}





	}

	.name-star-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 20px;
		padding-bottom: 6px;

		.star-rating-popular {
			display: flex;
			flex-shrink: 0;
			justify-content: flex-end;
			width: 120px;

			img {
				width: 23px;
				height: 23px;
			}
		}

		.card_desc_name_palace {
			transition: all 200ms ease-in-out;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 26px;
			line-height: 36px;
			padding-right: 10px;
			color: #1d293f;
		}
	}

	.place-name {
		font-family: 'Gilroy', sans-serif;
		font-weight: 500;
		font-size: 20px;
		line-height: 36px;
		color: #546179;
	}

	.swiper-slide {
		// cursor: pointer;
		// width: 100% !important;
		overflow: hidden;
		padding-bottom: 56.25%;

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			// height: 300px;
			//width: 100%;
		}
	}

	.card_description_body {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 500;
		color: #1d293f;

		font-size: 14.7656px; // mobile
		line-height: 17px;
	}

	.card_desc_price_include {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 15px;
		padding-bottom: 15px;

		font-size: 14.7656px;
		line-height: 17px;

		p {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			color: #1d293f;
		}

		.svg-wrapper {
			display: flex;
			flex-direction: row;

			svg {
				width: 22px; //mobile
				height: 22px; //mobile
				margin-right: 20px;
				font-size: 14.7656px;
				line-height: 26px;
			}

			svg:last-child {
				margin-right: 0px;
			}
		}
	}

	.price-description {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #546179;
		padding-bottom: 30px;
	}

	.price_wrap {
		display: flex;
		flex-direction: row;
		align-items: end;

		span {
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #546179;

			margin-bottom: 7px;
			margin-right: 10px;
		}

		p {
			color: #1d293f;
			display: inline-block;
			margin: 0px 0 4px;
			font-family: 'Gilroy', sans-serif;
			font-weight: 600;

			font-size: 20.918px; //mobile
			line-height: 26px;
		}

		.price_bold {
			display: inline-block;
			margin: 0px 0 4px;
			font-family: 'Gilroy', sans-serif;
			font-weight: 600;
			font-size: 34px;
			line-height: 42px;
			color: #1d293f;
			position: relative;
		}
	}

	.card_description_body {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #1d293f;
	}

	&:hover {
		.card_desc_name_palace {
			color: #3982cb;
		}
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (max-width:992px) {
	.slideComp {
		max-width: 700px;
		margin: auto;
	}

	.swiper-slide {
		padding-bottom: 0;
		// max-width: 500px;
	}
}

@media (max-width: 576px) {
	.slideComp {
		.price-description {
			padding-bottom: 20px;
		}

		.card_desc_price_include .svg-wrapper svg {
			margin-right: 14px;
		}

		.price_wrap {
			.price_bold {
				font-size: 20.918px;
				line-height: 26px;
			}
		}

		.name-star-wrapper .card_desc_name_palace {
			font-size: 15.9961px;
			line-height: 22px;
		}

		.hotel-stars {
			.hotel-star {
				width: 14px;
				height: 14px;
			}
		}

		.name-star-wrapper {
			.star-rating-popular {
				img {
					width: 14px;
					height: 14px;
				}
			}
		}

		.card_description_body {
			font-size: 14.7656px;
			line-height: 17px;
		}

		.card_desc_price_include .svg-wrapper svg {
			width: 13.5px;
			height: 13.5px;
		}

		.price_wrap span {
			font-size: 9.84375px;
			line-height: 12px;
		}
	}
}

.shape-hover {
	cursor: pointe;

}