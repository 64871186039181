.infoCard {
	border: 1px solid #E1E1E1;
	border-radius: 12px;
	padding: 30px;
	margin-bottom: 32px;

	p {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 22px;
		line-height: 26px;
		color: #212529;
		margin-top: 20px;
	}

	//@apply rounded-2xl border-[1px] border-[#E1E1E1] p-6 mb-9;
	.header {
		@apply flex justify-between items-center;

		.picture {
			display: flex;
			flex-direction: column;

			// @apply flex items-center;

			div {
				background: #E7F0F9;
				//margin-right: 1rem;
				border-radius: 100%;
				padding: 12px;
				width: 56px;
				flex-shrink: 0;
			}

			img {
				width: 32px;
				height: 32px;
				object-fit: cover;
			}
		}
	}

	.text {
		@apply ease-linear overflow-hidden;
		transition: all 500ms ease;
	}

	button {
		transition: all 500ms ease;
	}

	svg {
		@apply text-5xl text-gray-300 font-light;

	}

	.isVisible {

		// transition: all 500ms ease;
		// @apply rotate-180;
	}
}

@media (max-width: 576px) {
	.infoCard {
		padding: 16px;
		margin-bottom: 12px;

		p {
			font-size: 18px;
			line-height: normal;
			margin-left: 10px;
		}

		.header {
			.picture {
				flex-direction: row;
			}

		}
	}
}