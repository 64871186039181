.search-page-hotel-card {
	padding: 16px;



	.price_bold_wrapper_sp {
		position: relative;
	}

	.underline_sp {
		position: absolute;
		display: inline-block;
		height: 7px;
		width: 100%;
		background-color: #f5a623;
		opacity: 0.4;
		bottom: 4px;
		left: 0;
		z-index: 1;
	}

	.price_bold_sp {
		z-index: 8;
	}
}

.slideComp {
	user-select: none;
	transition: all 200ms ease;

	&:hover {
		h1 {
			color: #3982cb;
		}
	}

	.hotel-stars {
		.rating-star {
			.rating-star-icons-wrapper {
				cursor: pointer;
			}
		}
	}

	.image-slider-wrapper {
		position: relative;

		.img_sp {
			transition: all 0.3s ease;
			width: 100%;
			min-width: 100%;
			padding: 0 1px;
			border-radius: 20px;
			// height: 265px;
			background-size: cover;
			object-fit: cover;
			background-repeat: no-repeat;
		}

		.wrapper_ta_rating_image {
			position: absolute;
			z-index: 2;
			top: 15px;
			left: 15px;
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.ta_rating_image {
			display: flex;
			align-items: center;
			padding: 4.5px 8px;
			background-color: #ffffff;
			border-radius: 10px;
		}

		.ta_rating_image::before {
			content: '';
			display: block;
			margin-right: 6px;
			width: 18px;
			height: 18px;
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M8.99365 18.4873C13.9607 18.4873 17.9873 14.4607 17.9873 9.49365C17.9873 4.52659 13.9607 0.5 8.99365 0.5C4.02659 0.5 0 4.52659 0 9.49365C0 14.4607 4.02659 18.4873 8.99365 18.4873Z" fill="%2334E0A1"/><path d="M14.5487 7.73893L15.6339 6.55819H13.2272C12.0224 5.73518 10.5673 5.25586 8.99369 5.25586C7.42204 5.25586 5.97102 5.73621 4.76817 6.55819H2.35547L3.44074 7.73893C2.77552 8.34586 2.35849 9.22012 2.35849 10.1908C2.35849 12.0227 3.84372 13.5079 5.67561 13.5079C6.54584 13.5079 7.33867 13.1723 7.93055 12.6236L8.99371 13.7813L10.0569 12.6246C10.6488 13.1733 11.4406 13.5079 12.3108 13.5079C14.1427 13.5079 15.6299 12.0227 15.6299 10.1908C15.6309 9.21911 15.2139 8.34488 14.5487 7.73893ZM5.67659 12.4357C4.43656 12.4357 3.43171 11.4309 3.43171 10.1908C3.43171 8.9508 4.43659 7.94593 5.67659 7.94593C6.9166 7.94593 7.92148 8.9508 7.92148 10.1908C7.92148 11.4309 6.9166 12.4357 5.67659 12.4357ZM8.9947 10.1255C8.9947 8.64833 7.92049 7.38019 6.5026 6.83855C7.26932 6.51798 8.1104 6.34012 8.99369 6.34012C9.87697 6.34012 10.719 6.51798 11.4858 6.83855C10.0689 7.3812 8.9947 8.64836 8.9947 10.1255ZM12.3118 12.4357C11.0718 12.4357 10.0669 11.4309 10.0669 10.1908C10.0669 8.9508 11.0718 7.94593 12.3118 7.94593C13.5518 7.94593 14.5567 8.9508 14.5567 10.1908C14.5567 11.4309 13.5518 12.4357 12.3118 12.4357ZM12.3118 9.01309C11.6616 9.01309 11.1351 9.53966 11.1351 10.1898C11.1351 10.84 11.6616 11.3665 12.3118 11.3665C12.962 11.3665 13.4885 10.84 13.4885 10.1898C13.4885 9.54067 12.962 9.01309 12.3118 9.01309ZM6.8533 10.1908C6.8533 10.841 6.32674 11.3675 5.67659 11.3675C5.02645 11.3675 4.49989 10.841 4.49989 10.1908C4.49989 9.54067 5.02645 9.0141 5.67659 9.0141C6.32674 9.01309 6.8533 9.54067 6.8533 10.1908Z" fill="black"/></svg>');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		.ta_rating_image::after {
			content: '';
			display: block;
			margin-left: 2px;
			width: 14px;
			height: 14px;
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none"><path d="M12.824 6.75505L10.3576 8.90755L11.0965 12.1122C11.1356 12.2798 11.1244 12.4551 11.0644 12.6163C11.0044 12.7776 10.8982 12.9175 10.759 13.0187C10.6199 13.1199 10.454 13.1778 10.2822 13.1853C10.1103 13.1927 9.94005 13.1493 9.79271 13.0605L6.99763 11.3652L4.20857 13.0605C4.06122 13.1493 3.89097 13.1927 3.7191 13.1853C3.54723 13.1778 3.38136 13.1199 3.24224 13.0187C3.10311 12.9175 2.9969 12.7776 2.93689 12.6163C2.87688 12.4551 2.86572 12.2798 2.90482 12.1122L3.64255 8.91083L1.1756 6.75505C1.04512 6.64251 0.950768 6.49396 0.904379 6.32802C0.857989 6.16208 0.861626 5.98613 0.914833 5.82225C0.968039 5.65836 1.06845 5.51384 1.20347 5.40679C1.33848 5.29974 1.5021 5.23494 1.6738 5.22052L4.92552 4.93888L6.19482 1.91138C6.2611 1.75252 6.37291 1.61682 6.51615 1.52138C6.6594 1.42593 6.82768 1.375 6.99982 1.375C7.17195 1.375 7.34023 1.42593 7.48348 1.52138C7.62673 1.61682 7.73853 1.75252 7.80482 1.91138L9.07794 4.93888L12.3286 5.22052C12.5003 5.23494 12.6639 5.29974 12.7989 5.40679C12.9339 5.51384 13.0343 5.65836 13.0875 5.82225C13.1407 5.98613 13.1444 6.16208 13.098 6.32802C13.0516 6.49396 12.9573 6.64251 12.8268 6.75505H12.824Z" fill="%23FF7A00"/></svg>');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		.ta_rating_image span {
			color: #FF7A00;
			font-size: 16px;
			font-weight: 700;
		}

		.google_rating {
			// z-index: 2;
			// left: 15px;
			// right: auto;
			// top: 15px;
			// bottom: auto;
		}

		.google_rating::before {
			content: '';
			display: block;
			margin-right: 6px;
			width: 18px;
			height: 18px;
			background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M16.92 9.6875C16.92 9.1025 16.8675 8.54 16.77 8H9V11.195H13.44C13.245 12.2225 12.66 13.0925 11.7825 13.6775V15.755H14.46C16.02 14.315 16.92 12.2 16.92 9.6875Z" fill="%234285F4"/><path d="M8.99977 17.7502C11.2273 17.7502 13.0948 17.0152 14.4598 15.7552L11.7823 13.6777C11.0473 14.1727 10.1098 14.4727 8.99977 14.4727C6.85477 14.4727 5.03227 13.0252 4.37977 11.0752H1.63477V13.2052C2.99227 15.8977 5.77477 17.7502 8.99977 17.7502Z" fill="%2334A853"/><path d="M4.38 11.0677C4.215 10.5727 4.1175 10.0477 4.1175 9.50023C4.1175 8.95273 4.215 8.42773 4.38 7.93273V5.80273H1.635C1.0725 6.91273 0.75 8.16523 0.75 9.50023C0.75 10.8352 1.0725 12.0877 1.635 13.1977L3.7725 11.5327L4.38 11.0677Z" fill="%23FBBC05"/><path d="M8.99977 4.535C10.2148 4.535 11.2948 4.955 12.1573 5.765L14.5198 3.4025C13.0873 2.0675 11.2273 1.25 8.99977 1.25C5.77477 1.25 2.99227 3.1025 1.63477 5.8025L4.37977 7.9325C5.03227 5.9825 6.85477 4.535 8.99977 4.535Z" fill="%23EA4335"/></svg>');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}

		@media (max-width: 576px) {
			.wrapper_ta_rating_image {
				top: 10px;
				left: 10px;
				padding: 4.5px 6px;
				border-radius: 6px;
			}

			.google_rating {
				left: 10px;
				right: auto;
				top: 10px;
				bottom: auto;
			}

			.google_rating::before {
				margin-right: 4px;
				width: 16px;
				height: 16px;
			}

			.ta_rating_image::before {
				margin-right: 4px;
				width: 16px;
				height: 16px;
			}

			.ta_rating_image span {
				font-size: 14px;
			}
		}
	}

	.card_desc_name_palace_sp {
		max-width: 75%;
		padding-right: 0;
	}

	.name-star-wrapper_sp {
		align-items: center;
	}

	.card_desc_price_include_sp {
		margin: 9px 0 33px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
	}
}

@media (max-width: 576.8px) {
	.search-page-hotel-card {
		.underline_sp {
			position: absolute;
			display: inline-block;
			height: 4px;
			width: 100%;
			background-color: #f5a623;
			opacity: 0.4;
			bottom: 0px;
		}
	}
}

@media (max-width: 1400px) {
	.search-page-hotel-card {
		.slideComp {
			.card_desc_name_palace_sp {
				max-width: 70%;
			}
		}
	}
}