@import '@/assets/styles/variables.scss';

.pagination{
  width: 100%;
  flex-direction: row;
  justify-content: space-between!important;
  @apply flex text-2xl justify-center items-center my-10;
  color:$gray-1;
  button{
    display: block;
  }
  svg{
    @apply mr-2 text-[40px] cursor-pointer;
    color: white;
    &:hover{
      color:white;
    }
    background: #3982CB;
    border-radius: 100%;
    border:1px solid transparent;

    margin-right: 0;
    margin-left: 0;
  }
  .items{
    @apply flex;
    p{
      @apply mr-4 cursor-pointer;
      &:hover{
        color: $gray-2;
      }
      flex-direction: row;
      justify-content: space-between;
     
    }
    .paginationItem{
      cursor: pointer;
      //margin-left: 5px;
      // padding: 2px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      line-height: 32px;
      text-align: center;
      transition: all 200ms ease;
      user-select: none;
      &:hover{
        color: white;
        background-color: #3982CB;
      }
      &:last-child{
        //margin-right: 10px;
      }
    }
    .dots{
      user-select: none;
      //margin-left: 15px;
      padding: 2px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      line-height: 40px;
      text-align: center;
    }
    .active{
      color: white;
      background-color: #3982CB;
      cursor: not-allowed;
      &:hover{
       
      }
    }
  }
  .disable{
    svg{
      @apply cursor-not-allowed;
      color:$gray-1 !important;
      background: transparent;
      border: 1px solid gray;
    }
  }
  .button{
    height: fit-content;
    button{
      height: fit-content;
    }
  }
}
