.flightInfo_wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  left: 0;
  top: 0;
}

.popup {
  position: absolute;
  background-color: white;
  left: 50%;
  top: calc(50% + 50px);
  transform: translate(-50%, -50%);
  border: 1px solid #E1E1E1;
  border-radius: 8px;
  padding: 45px;
  z-index: 50;

  p {
    white-space: nowrap;
  }

  .blueLineContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .blueLine {
    width: 50px;
    height: 2px;
    background: #3982CB;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .mb_20 {
    margin-bottom: 20px;
  }
  .mb_8 {
    margin-bottom: 8px;
  }

  .timeCode {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.005em;
    color: #9A9AB0;
  }
  .time {
    font-weight: 700;
    color: #11142D;
  }

  .titleText {
    letter-spacing: 0.005em;
    color: #11142D;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
  }
  .textGrey {
    font-size: 20px;
    color: #9A9AB0;
  }
  .textBlack {
    font-size: 20px;
    color: #11142D;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title_mr {
      margin-right: 20px;
    }
    .cross {
      position: relative;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .cross:before,
    .cross:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 2px;
      background-color: black;
    }

    .cross:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .cross:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  .container {
    .box:first-child {
      margin-right: 50px;
    }
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }
}


@media (max-width: 992px) {
  .popup {
    padding: 20px;

    .box:last-child {
      margin-top: 25px;
    }

    .container {
      display: flex;
      flex-direction: column;
    }
    .column {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .blueLineContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      height: 54px;
      justify-content: center;
    }

    .timeAndFlight {
      margin-left: 14px
    }

    .blueLine {
      transform: rotate(-90deg);
      width: 35px;
      position: absolute;
    }
    .blueLine1 {
      top: -10px;
    }
    .blueLine2 {
      bottom: -10px;
    }

    .airplane {
      width: 16px;
      height: 16px;
    }

  }

}

@media (max-width: 576px) {
  .popup {
    .titleText {
      font-size: 20px;
    }
    .textGrey {
      font-size: 18px;
    }
    .textBlack {
      font-size: 18px;
    }
    .timeCode {
      font-size: 14px;
    }
  }
}

@media (max-width: 1199px) {

}