.getOffer4 {
	color: white;

	.header {
		text-align: center;
		h1 {
			margin-top: 55px;
			margin-bottom: 10px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 600;
			font-size: 48px;
			line-height: 57px;
			color: #FFFFFF;
			text-align: center;
		}
		h2 {
			width: fit-content;
			font-weight: 500;
			font-size: 1.6rem;
			margin: 15px auto 30px 30px;
			padding-bottom: 5px;
			margin: auto;
		}
	}
	.table {
		display: flex;
		flex-wrap: wrap;
		max-width: 900px;
		margin: auto;
		justify-content: space-around;
		.item {
			width: 30%;
			margin: 20px 20px 0 0;
			text-align: center;
			border: 2px solid white;
			padding: 10px;
			border-radius: 10px;
			font-weight: 500;
			font-size: 1.7rem;
			cursor: pointer;
			&:nth-child(3) {
				margin-right: 0;
			}
			&:nth-child(6) {
				margin-right: 0;
			}
		}
		.item:last-child {
			margin-bottom: 0;
		}
	}
	.active {
		background-color: #ffffff;
		color: #3982cb;
	}

	@media (max-width: 767px) {
		.table {
			margin-left: 30px;
			margin-right: 30px;
			flex-direction: column;
			.item {
				width: 100%;
			}
			
		}
		.header {
			// margin-top: 200px;
			h1 {
				text-align: left;
				//padding-left: 15px;
				//padding-right: 15px;
			}
			h2 {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 26px;
				line-height: 30px;
				color: #FFFFFF;
				text-align: center;
			}
		}
	}
}
@media (max-width: 1199.98px) and (min-width: 576px) {
	.getOffer4 {
		.header {
			margin-left: 30px!important;
			margin-right: 30px;
			h1 {
				//margin-left: 30px!important;
				//margin-right: 30px;
			}
		}
	}
}

@media (max-width:576px) {
	.getOffer4 {
		//padding-top: 30px;
		.header {
			text-align: left !important;
			h1 {
				margin-top: 0!important;
				font-size: 30px!important;
				line-height: 32px!important;
				padding-left: 15px;
				padding-right: 15px;
			}
			h2 {
				text-align: left!important;
				font-size: 20px!important;
				line-height: 23px!important;
				font-weight: 400!important;
				padding-right: 15px!important;
				padding-left: 15px!important;
				padding-bottom: 0;
			}
		}
	}

	.table{
		margin-left: 15px !important;
		margin-right: 15px !important;
		margin-top: 10px;
		.item{
			margin-top: 15px!important;
			font-size: 1rem !important;
			padding-top: 18px !important;
			padding-bottom: 18px !important;
			margin-right: 0 !important;
			//margin-top: 8px !important;
		}
	}
	.textarea{
		margin: 0 30px !important;
	}
}
