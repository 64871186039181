.activeHeader {
	position: fixed !important;
	top: -180px !important;
	background-color: white !important;

	.nav_wrap .menu-item {
		color: #546179 !important;
		font-weight: 600 !important;
		border-bottom: 1px solid transparent;
		transition: all 200ms ease-in-out;

		&:hover {
			border-bottom: 1px solid white;
			color: white;
		}
	}

	.nav_wrap .wrap_contact span,
	img {
		color: #546179 !important;
	}

	svg {
		color: #3982cb !important;
	}

	.lang_body {
		border: 1px solid #3982cb !important;

		span {
			color: #3982cb !important;
		}
	}

	// logo{
	.active-head {
		border-bottom: 2px solid gray !important;
	}

	.menu-item:hover {
		border-bottom: 2px solid gray !important;
	}

	// }
	z-index: 900;
}

.activeHeaderAnimate {
	transition: all 1s ease;
	top: 0 !important;

	.language-change {
		border: 1px solid #3982cb !important;
	}
}

.header_transparent {
	background-color: transparent;
	width: 100%;
	position: absolute;
	top: 0;
	border-bottom: 1px solid #e0e4e7;

	.container-xxl {
		max-width: 1600px;
	}

	.nav_wrap {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			display: flex;
			max-width: 265px;

			/* flex: 1 1 auto; */
			img {
				width: 197px;
				height: 50px;
			}
		}

		.language-change {
			position: absolute;
			transition: all 500ms ease;
			opacity: 0;
			pointer-events: none;
			background: white;
			width: 100%;
			margin-top: 10px;
			color: gray;
			border-radius: 10px;
			padding: 12px;
			border: 1px solid transparent;

			.language-active {
				color: #3982cb;
			}

			p {
				text-align: center;
				font-size: 1.3rem;
				font-weight: 500;
				transition: all 300ms ease;
				cursor: pointer;

				&:hover {
					color: #3982cb;
				}
			}
		}

		.language-change-active {
			display: block;
			opacity: 1;
			pointer-events: all !important;
			z-index: 999999999;
		}

		.wrap_btn_offer {
			display: flex;
			justify-content: center;
			max-width: 498px;
			transition: all 200ms ease;

			&:hover {
				transform: scale(1.07);
			}

			.btn_.get-offer {
				padding: 15px 27px;
				background: #3982cb;
				border-radius: 12px;
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 20px;
				color: #ffffff;
			}

			.hvr-event {
				transition-property: transform;
				transition: all 0.3s ease;
			}
		}

		.wrap_contact {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 60px;
			align-items: flex-start;
			max-width: 303px;

			.contactbody_ {
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 26px;
				letter-spacing: 1px;
				color: #ffffff;
				display: flex;
				align-items: center;

				svg {
					margin-right: 15px;
				}
			}
		}

		ul.nav_.menu {
			margin: 0;
			padding: 0;
			display: flex;
			max-width: 380px;
			flex: 1 1 auto;
			justify-content: space-between;

			.active-head {
				border-bottom: 2px solid white;
			}

			li {
				list-style: none;
				display: inline;
				font-family: 'Gilroy', sans-serif;
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				color: #ffffff;
				border-bottom: 2px solid transparent;
				transition: all 0.3s ease;

				&:hover {
					border-bottom: 2px solid white;
					color: white;
				}
			}
		}

		button.lang_body {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 78px;
			height: 44px;
			justify-content: space-evenly;
			background: transparent;
			border-radius: 12px;
			border: 1px solid #fff;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 20px;
			color: #fff;

			img {
				margin-right: 8px;
			}
		}
	}
}

@media (max-width: 1199.98px) {
	.header_transparent {
		.nav_wrap {}
	}
}

.bg-gray-wrapper {
	.header_transparent {
		background-color: #a69da5;
	}
}


.blog-page {
	.articles_row.row.row {
		flex-wrap: wrap;
	}
}

.max-w-full {
	.carusel_wrap {
		.articles_row .col-12:nth-child(n+4) {
			display: none;
		}
	}
}