
.block_title {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  color: #1D293F;
}

.block_description {
  margin-top: 7px;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  color: #546179;
}
.advantages_description {
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #777E91;;
}

.advantages {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: auto;
  flex: 1 1 auto;
  max-width: 1024px;
  //margin-top: 40px;
  margin-bottom: 36px;

  .advantages-card {
    // max-width: 80% ;
    max-width: 273px;
    margin-top: 40px;

    img {
      margin: auto;
      vertical-align: top;
    }

    .advantages_title {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      color: #23262F;
      margin-bottom: 8px;
    }

  }
}


//////////////////////////////////////////////////////////////////////////

@media (max-width: 991.98px) {
  .advantages {
    flex-direction: column;

    .advantages-card {
      margin: auto;
    }
    .advCard:not(:first-child) {
      margin-top: 13px;
    }
  }
}

@media (max-width: 992px) and (min-width: 576px) {
  .block_title {
    font-size: 50px;
    line-height: 60px;
  }
  .block_description {
    line-height: 30px;
  }
  .advCard.advCard:not(:first-child) {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .container-xxl {
    padding: 0 15px;
  }

  .block_title {
    font-size: 25px;
    line-height: 32px;
  }
  .block_description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 20px;
  }

  .advantages {
    .advantages-card {
      .advantages_title {
        font-size: 21.4375px;
        line-height: 24px;
        margin-top: 0px;
        margin-bottom: 6px;
      }

      .advantages_description {
        font-size: 13.7812px;
        line-height: 18px;
      }
    }
  }
}


