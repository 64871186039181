.guests {
	display: flex;
	position: absolute;
	flex-direction: column;
	width: 170%;
	border-radius: 15px;
	top: 92px;
	left: -35%;
	background: white;
	padding: 15px 25px 15px 25px;
	box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
		0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);

	.item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #868f98;
		padding-bottom: 5px;
		padding-top: 5px;

		&:first-child {
			border-top: none;
		}

		p {
			&:first-child {}

			&:last-child {
				font-size: 0.7rem;
				color: #868f98;
			}
		}
	}

	.buttons {
		display: flex;

		.disabled {
			border: 1px solid gray;
			color: gray;
		}

		button {
			border-radius: 100%;
			border: 1px solid #3982cb;
			width: 20px;
			height: 20px;
			line-height: 20px;
			color: #3982cb;
		}

		p {
			width: 10px;
			margin: 0 5px 0 5px;
			text-align: center;
		}
	}

	.styleButtonArrow {
		display: none;
	}
}

@media (max-width:1150px) {
	.guests {
		position: relative;
		// bottom: 0px !important ;
		left: 0;
		padding: 38px 24px 24px;
		border-radius: 16px;
		top: 0;
		width: 100%;
		height: fit-content;
		z-index: 9999;
		background-color: #fff;
	}

	.styleButtonArrow {
		display: block !important;
		top: 10px;
		right: 10px;
	}
}