.getOffer2 {
	color: white;
}
.header {
	text-align: center;
	h1 {
		margin-top: 55px;
		margin-bottom: 10px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 48px;
		line-height: 57px;
		color: #FFFFFF;
		text-align: center;
	}
	h3 {
		width: fit-content;
		opacity: 0.7;
		font-weight: bold;
		font-size: 1.5rem;
		margin: 15px auto 30px auto;
		border-bottom: 2px solid rgba(#ffffff, 0.7);
		padding-bottom: 5px;
	}
}
@media (max-width: 650px) {
	.header {
		text-align: left;
		h1 {
      margin-left: 15px;
		}
	}
}
@media (max-width: 577px) {
	.header {
		text-align: center;
		h1 {
			font-size: 30px;
			line-height: 32px;
			font-weight: 600;
			margin-left: 15px;
			margin-right: 20px;
			text-align: left !important;
			margin-top: 0px!important;
		}
	}
}
