.card {
	position: relative;
	width: calc(33%);
	text-align: left;
	margin-left: 0;
	.skeletonCard {
		border-radius: 20px;
		height: 100%;
	}
	.cardImg {
		width: 100%;
		height: 280px;
	}
	.header {
		display: flex;
		justify-content: space-between;
		.rating {
			width: 130px;
			margin-left: 30px;
		}
		.skeletonHeader {
			display: block;
			width: 100%;
		}
	}
}

.visibleDisplaySkeleton{
	padding-bottom: 72px;
}

@media (max-width:993px) {
	.card{
		width: 100%;
		.cardImg{
			height: 350px;
		}
	}
	.visibleDisplaySkeleton{
		display: block !important;
		>div{
			&:nth-child(2){
				display: none;
			}
			&:nth-child(3){
				display: none;
			}
		}
	}
}
@media (max-width:600px) {
	.card{
		width: 100%;
		.cardImg{
			height: 300px;
		}
	}
}
@media (max-width:400px) {
	.card{
		width: 100%;
		.cardImg{
			height: 230px;
		}
	}
}