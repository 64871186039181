.button{
  margin-top: 18px;
  background-color: transparent;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  border-bottom: 2px solid #fff;
  color: #FFFFFF;
}

@media (max-width: 576px) {
  .button {
    font-size: 20px;
    line-height: 23px;
  }
}