.loadingPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    animation-name: blink;
    animation-timing-function: linear;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;

    width: 200px;
    height: 51px;
  }
}

.loadingWrapper {
  position: static;
  margin-top: 16px;
  border-radius: 20px;
  width: 100%;
  height: 20px;
  background-color: #3982CB;
  background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
  background-size: 1rem 1rem;
  animation: progress-bar-stripes 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
    font-family: 'Gilroy', sans-serif;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  100% {
    background-position: 0 0;
  }
}


@keyframes blink {
  50% {
    opacity: 0;
  }
}