.blog-page {
  //margin-top: 100px;
  .block_title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
    color: #1D293F;
  }
  .block_description{
    text-align: center;
  }
  .center_title {
    margin-top: 80px;
    text-align: center;
  }
 
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media (max-width: 576px) {
  .blog-page {
    .block_title {
      font-size: 25px;
      line-height: 32px;
    }
    .center_title.blog_title {
      margin-top: 30px;
      text-align: left;
    }
  }
}