.form {
	.form-item {
		width: 100%;
		position: absolute;
		left: -2px;
		top: 110px;
		background: white !important;
		border-radius: 15px;
		padding: 20px 20px 20px 30px;
		z-index: 2221111;

		&.form_modal_country_code {
			min-width: 177px;
		}

		&.form_modal_toun_from {
			min-width: 177px;
		}

		.checkStyle {
			position: relative;

			.checkMark {
				position: absolute;
				top: 5px;
				left: -17px;

				svg {
					color: #3982cb;
				}
			}
		}

		.directionText {
			cursor: pointer;
			transition: all 100ms ease;
			margin-bottom: 15px;

			&:hover {
				color: #3982cb;
			}

			&.activeElement {
				color: #3982cb !important;
			}

			&.last_child {
				margin-bottom: 0;
			}
		}


		.flagIconLine {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		.flagIcon {
			width: 24px;
			height: 16px;
			object-fit: cover;
			border-radius: 3px;
		}


		border: 1px solid rgb(205, 202, 202);
	}

	@media (max-width: 1000px) {
		.form-item {
			position: fixed;
			border-radius: 0;
			box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6,
				0 -1px 0 #e6e6e6, 0 3px 13px rgb(0 0 0 / 8%);
			bottom: 0px;
			top: auto;
			left: 0;
			// padding-top: 50px !important;
		}
	}

	.nights {
		width: 350px;

		.description-nights {
			width: max-content;
			margin-top: 1px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #868f98;
		}
	}

	.people_counter_wrapper {
		display: flex;
		width: 74px;
		//margin-left: 54px;
		align-items: center;
		justify-content: space-between;
	}

	.btn_counter_people.minus {
		border: 1.2px solid #a4adb6;
	}

	.btn_counter_people.minus::before {
		content: '';
		display: block;
		width: 8px;
		height: 1.2px;
		background-color: #868f98;
	}

	.btn_counter_people.minus.active {
		border: 1.2px solid #3982cb;
	}

	.btn_counter_people.minus.active::before {
		background-color: #3982cb;
	}

	.btn_counter_people {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 2px solid #ffffff;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: transparent;
		transition: all 0.3s ease;
	}

	.btn_counter_people.plus {
		border: 1.2px solid #a4adb6;
	}

	.btn_counter_people.plus.active {
		border: 1.2px solid #3982cb;
	}

	.btn_counter_people.plus.active::before {
		background-color: #3982cb;
	}

	.btn_counter_people.plus.active::after {
		background-color: #3982cb;
	}

	.btn_counter_people.plus::before {
		content: '';
		display: block;
		width: 8px;
		height: 1px;
		background-color: #a4adb6;
	}

	.btn_counter_people.plus::after {
		position: absolute;
		content: '';
		display: block;
		width: 8px;
		height: 1px;
		transform: rotate(90deg);
		background-color: #a4adb6;
	}

	.audits {
		width: 300px;

		.row_people_counter {
			display: flex;
			justify-content: space-between;
			// max-width: 350px;
			align-items: center;
		}

		.line {
			width: 100%;
			margin: 12px 0;
			height: 1px;
			background-color: #dce5eb;
		}

		.title {
			font-family: 'Gilroy', sans-serif;
			font-weight: 400;
			font-size: 18px;
			line-height: 21px;
			color: #212529;
		}

		.description-text {
			width: max-content;
			margin-top: 1px;
			font-family: 'Gilroy', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #868f98;
		}
	}

	@media (min-width: 1200px) {
		.audits {
			left: -90px;
		}

		.nights {
			left: -80px;
		}
	}

	@media (max-width: 1000px) {}

	@media (min-width: 1000px) and (max-width: 1200px) {
		.audits {
			width: 100% !important;
		}

		.nights {
			width: 100% !important;
		}
	}

	@media (max-width: 1000px) and (max-width: 1200px) {
		.audits {
			width: 100% !important;
		}

		.nights {
			width: 100% !important;
			justify-content: space-between !important;
		}
	}
}


@media (max-width: 1000px) {
	.form .form_modal_wrapper {
		background: rgb(0 0 0 / 30%);
		height: 100vh;
		width: 100vw;
		position: fixed;
		left: 0;
		top: 0;
	}

	.form .form_modal_wrapper {
		background: rgb(0 0 0 / 30%);
		height: 100%;
		width: 100%;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 999;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: center;

		.form_modal {
			position: relative;
			border-radius: 16px;
			box-shadow: none;
			border: 0;
			padding: 38px 24px 24px;

			&.form_modal_eat {
				padding: 38px 24px 24px 40px;
			}
		}


		.crossClose {
			top: 10px;
			right: 10px;
		}
	}
}